import React, { useEffect, useState } from "react";
import { useTheme, Typography, Box } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { getPageNotFoundStyle } from "../../components/customstyles/PageNotFoundStyle";
const useStyles = () => {
  const theme = useTheme();
  return getPageNotFoundStyle(theme);
};

function ErrorDetails({ errorDetails }) {
  const styles = useStyles();
  const [medBVError, setMedBVError] = useState("Error.");

  useEffect(() => {
    if (typeof errorDetails === "string") {
      setMedBVError(errorDetails);
    } else if (typeof errorDetails === "object") {
      setMedBVError(`${JSON.stringify(errorDetails?.errors)}`);
    }
  }, []);
  return (
    <Box sx={styles.textStyle}>
      <ErrorOutlineIcon sx={styles.bvPillIcon} />
      <Typography sx={styles.headingText}>
        Benefits Verification not submitted!
      </Typography>
      <Typography sx={styles.subHeadingText}>
        There was an error with your submission:
      </Typography>
      <Typography sx={styles.errorMessageStyle}>{`[${medBVError}]`}</Typography>
    </Box>
  );
}
export default ErrorDetails;
