import React from "react";
import Autosuggest from "react-autosuggest";
import { TextField, InputAdornment } from "@mui/material";
import MenuItem from "../../components/customcomponents/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import "./ReactAutoSuggest.css";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

function Search({ list, pathToSearch, filterList, searchPlaceholder }) {
  const query = useQuery();
  const history = useHistory();
  const [value, setValue] = React.useState(query.get("search") || "");
  const [suggestions, setSuggestions] = React.useState([]);

  const getSuggestions = (newValue, newList) => {
    const inputValue = newValue.trim().toLowerCase();
    const inputLength = inputValue.length;
    let result;
    result =
      inputLength === 0
        ? []
        : newList.filter((obj) =>
            `${_.get(obj, pathToSearch)}`.toLowerCase().includes(inputValue)
          );
    return result;
  };

  const getSuggestionValue = (suggestion) => {
    return _.get(suggestion, pathToSearch);
  };
  const getInputBasedSuggestion = (suggestion) => {
    return `${_.get(suggestion, pathToSearch)}`;
  };
  const renderSuggestion = (suggestion) => {
    return <MenuItem>{getInputBasedSuggestion(suggestion)}</MenuItem>;
  };
  const renderInputComponent = (inputProps) => {
    const { placeholder, ...other } = inputProps;
    return (
      <TextField
        fullWidth
        variant="outlined"
        label={placeholder}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {value.trim() ? (
                <ClearIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => onChange(null, { newValue: "" })}
                />
              ) : (
                <SearchIcon />
              )}
            </InputAdornment>
          ),
          style: { backgroundColor: "#fff", minHeight: "47px" },
          classes: {
            input: "searchInput",
          },
        }}
        {...other}
      />
    );
  };
  const onChange = (_e, { newValue }) => {
    setValue(newValue);
    if (newValue === "") {
      history.replace({
        search: "",
      });
      filterList(list);
    }
  };

  const onSuggestionsFetchRequested = async () => {
    if (value) {
      let filteredList = await getSuggestions(value, list);
      setSuggestions(filteredList);
      return filteredList;
    } else {
      return list;
    }
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const onSuggestionSelected = (_event, { suggestion }) => {
    const suggestionValue = `${_.get(suggestion, pathToSearch)}`;
    history.replace({
      search: `?search=${suggestionValue}`,
    });
    setValue(suggestionValue);
    filterList([suggestion]);
  };
  React.useEffect(() => {
    (async () => {
      const filteredList = await onSuggestionsFetchRequested();
      filterList(filteredList);
    })();
  }, [list]);

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      theme={{
        container: "react-autosuggest__container",
        suggestionsContainerOpen: "react-autosuggest__suggestionsContainerOpen",
        suggestionsList: "react-autosuggest__suggestionsList",
        suggestion: "react-autosuggest__searchResultList",
      }}
      inputProps={{
        placeholder: searchPlaceholder,
        value,
        onChange,
      }}
    />
  );
}

export default Search;
