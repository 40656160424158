import React from "react";
import { useHistory } from "react-router-dom";
import SuccessDialog from "./SuccessDialog";
import { Typography, useTheme } from "@mui/material";
import { insuranceCoverageDetailsStyle } from "./customstyles/DashboardStyle";
const useStyles = () => {
  const theme = useTheme();
  return insuranceCoverageDetailsStyle(theme);
};
function LogOutDialog({ setShowLogoutDialog, logout }) {
  const styles = useStyles();
  const loginBy = localStorage.getItem("loginBy");
  const navigate = useHistory();
  return (
    <SuccessDialog
      HeaderText={
        <Typography sx={styles.dialogHeading}>
          End EYLEA-NOW session?
        </Typography>
      }
      successButton={[
        <Typography
          onClick={() => setShowLogoutDialog(false)}
          sx={styles.dialogbtnStyle}
        >
          Cancel
        </Typography>,
        <Typography
          onClick={async () => {
            const loginBy = localStorage.getItem("loginBy");
            await logout();
            setShowLogoutDialog(false);
            if (loginBy === "email") {
              navigate.replace("/login");
            } else {
              navigate.replace("/logout");
            }
          }}
          sx={styles.dialogbtnStyle}
        >
          End Session
        </Typography>,
      ]}
      BodyText={
        loginBy === "email" ? (
          <Typography sx={styles.dialogBodytext}>
            Are you sure you want to end this session? You will need to log in
            to EYLEA-NOW again with another OTP (one-time password).
          </Typography>
        ) : (
          <Typography sx={styles.dialogBodytext}>
            Are you sure you want to end this session? You may re-open EYLEA-NOW
            again through any patient’s record in your EHR.
          </Typography>
        )
      }
      dismiss={""}
    />
  );
}
export default LogOutDialog;
