import { createTheme } from "@mui/material";
const theme = createTheme({
  textColour: {
    headingColour: "#2F363C",
    dialogBodytext: "#535A6E",
    textTertiary: "rgba(47, 54, 60, 0.77)",
    primaryColor: "#00446B",
    emrTextColor: "rgba(47, 54, 60, 0.87)",
  },
  btnColour: {
    btnBackgroundColour: "#00446B",
    btnTextColour: "#fff",
    btnSecondaryBackground: "rgba(105, 87, 178, 0.08)",
  },
  custom: {
    activeNavLink: "#00446B",
    colorWhite: "#fff",
    buttonDisabledBg: "rgba(243, 244, 245, 1)",
    buttonDisabledTextClr: "rgba(83, 108, 112, 0.54)",
    buttonDisabledBdrClr: "rgba(243, 244, 245, 1)",
    background: "#E9F4FE",
    iFrameBorder: "#dddddd",
  },
  palette: {
    customInput: {
      main: "#00446B",
    },
    common: {
      black: "#000",
      white: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#fff",
      primary: "rgba(0, 68, 107, 0.08)",
      hovered: "rgba(0, 0, 0, 0.04)",
    },
    secondary: {
      main: "#ffff00",
    },
    primary: {
      light: "#406F99",
      dark: "",
      main: "#2262bf",
    },

    border: {
      primaryBorder: "#2262bf",
      secondaryBorder: "#4A4A4A",
      thirdBorder: "",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    accent3: {
      main: "#382D82",
      background: "rgba(105, 87, 178, 0.08)",
    },
    text: {
      linkColor: "#469CD5",
      cardContentColor: "#535A6E",
      btnBackgroundColour: "#2C919914",
      textPrimaryColor: "#3ff366",
      textSecondaryColor: "#2F363C",
      textThirdColor: "#2F363C",
      textFourthColor: "rgba(5, 6, 6, 0.38)",
      textFifthColor: "#8E8E8E",
      textSixthColor: "#7E7871",
      primaryButtonTextColor: "#FFFFFF",
      titleColor: "#4990e2",
      primary: "#515974",
      secondary: "#515974",
      third: "#fff",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    hoverPrimaryColor: {
      main: "#f09e0d",
    },
    hoverSecondaryColor: {
      main: "#ffff00",
    },
    hoverThirdColor: {
      main: "#fff",
    },
    minWidthMenu: {
      minWidthMenu: 155,
    },
    logo: {
      logoSize: 150,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: "'Roboto', 'Helvicta','Arial',sans-serif",
    button: {
      textTransform: "inherit",
    },
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:last-child td.MuiTableCell-footer": {
            borderBottom: 0,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          outline: "none !important",
          cursor: "default",
        },
        columnSeparator: {
          opacity: "0 !important",
        },
        cell: {
          ":focus": {
            outline: "none",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#f4f5f5",
          "&:hover": {
            backgroundColor: "#f4f5f5",
          },
        },
      },
    },
    PrivateNotchedOutline: {
      styleOverrides: {
        legend: {
          width: "inherit !important",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          whiteSpace: "nowrap",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: { padding: "6px 0px" },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          left: "-.5%",
        },
        root: {
          padding: "0 2px",
          background: "#fff",
          color: "#2F363CC4",
          borderRadius: "5px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: "inherit",
          padding: "10px 0 7px",
          "&:-webkit-autofill,-webkit-autofill:hover,-webkit-autofill:focus": {
            boxShadow: "none",
            transition: "background-color 5000s ease-in-out 0s",
          },
          "&:-webkit-autofill:disabled": {
            boxShadow: "none",
            transition: "background-color 5000s ease-in-out 0s",
          },
        },
      },
    },
    MuiGridListTile: {
      styleOverrides: {
        root: {
          width: "100% !important",
          height: "inherit !important",
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          display: "inherit",
        },
      },
    },

    MuiStepConnector: {
      styleOverrides: {
        root: {
          "&.Mui-active": {
            background: "#f09e0d",
            width: "3.3px",
            marginLeft: 11,
          },
          "&.Mui-completed": {
            background: "#f09e0d",
            width: "3.3px !important",
            height: 50,
            borderLeftWidth: "3.3px !important",
          },
          "&.Mui-vertical": {
            padding: 0,
          },
          "&.Mui-lineVertical": {
            borderColor: "#429ed5",
            minHeight: 50,
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fill: "#fff",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          "&.Mui-label": {
            paddingLeft: 20,
          },
          "&.Mui-iconContainer": {
            paddingRight: "0px",
          },
          "&.Mui-active": {
            color: "#f09e0d !important",
          },
          "&.Mui-completed": {
            color: "rgba(218, 223, 224, 1) !important",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "17px 0px 17px 8px",
          lineHeight: "1.1876em",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: "10px",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          overflow: "visible !important",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          minHeight: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperScrollPaper: {
          maxHeight: "inherit !important",
        },
        paperFullWidth: {
          width: "100% !important",
          margin: "0px !important",
        },
        root: {
          padding: "16px 16px 45px !important",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "0px",
          "&:last-child": {
            paddingBottom: 0,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
});
export default theme;
