import React, { useContext, useEffect, useState } from "react";
import {
  useTheme,
  CircularProgress,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import GridContainer from "../../components/grid/GridContainer.jsx";
import GridItem from "../../components/grid/GridItem.jsx";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import MuiDataGrid from "../../components/MuiDataGrid.jsx";
import Search from "../../components/customcomponents/Search";
import { getDashboardStyle } from "../../components/customstyles/DashboardStyle";
import { AuthContext } from "../../context/AuthContext.jsx";
import Button from "../../components/customcomponents/CustomButton.jsx";

const useStyles = () => {
  const theme = useTheme();
  return getDashboardStyle(theme);
};

function PayersDashboard() {
  const styles = useStyles();
  const history = useHistory();
  const [insuranceData, setInsuranceData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { makeAuthenticatedApiCall } = useContext(AuthContext);

  const tablecolumns = [
    {
      field: "siteInsuranceName",
      flex: 1,
      renderHeader: () => {
        return <Typography sx={styles.tableLabelColor}>Plan Name</Typography>;
      },
      renderCell: (params) => {
        return (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Typography sx={styles.TableBodyText}>{params.value}</Typography>
            <Button
              sx={styles.payerViewDetailsBtn}
              onClick={() => {
                history.push({
                  pathname: `/payers/${params.value}`,
                });
              }}
            >
              VIEW DETAILS
            </Button>
          </Stack>
        );
      },
      resizable: false,
      aggregable: false,
      gropable: false,
      hideable: false,
      pinnable: false,
    },
  ];

  useEffect(() => {
    const getInsuranceDetails = async () => {
      const response = await makeAuthenticatedApiCall(
        "get",
        `/rgnserver/api/insuranceplan/getsiteplandetails`,
        {}
      );
      if (response?.status === 200 && response.data.length > 0) {
        setInsuranceData(response.data);
      }
      setLoading(false);
    };
    getInsuranceDetails();
  }, []);

  const changePayerList = (selectedPayerList) => {
    if (selectedPayerList !== undefined) {
      if (selectedPayerList.length > 0) {
        setTableData(selectedPayerList);
      } else if (selectedPayerList.length === 0) {
        setTableData([]);
      } else {
        setTableData(null);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Payer Watchlist</title>
      </Helmet>
      {loading ? (
        <Box sx={styles.loadingLeftStyle}>
          <Box sx={{ marginBottom: "20px" }}>
            <CircularProgress />
          </Box>
          <Typography sx={styles.loadinText}>
            Loading payers details...
          </Typography>
        </Box>
      ) : (
        <Box sx={styles.tableStyle}>
          <GridContainer>
            <GridItem lg={4} md={4} sm={12} xs={12} sx={styles.gridAlign}>
              <p style={styles.payersHeading}>Payer Watchlist</p>
              <Search
                pathToSearch={"siteInsuranceName"}
                searchPlaceholder={"Search Payers"}
                list={insuranceData}
                filterList={changePayerList}
              />
            </GridItem>
            <GridItem md={12}>
              <MuiDataGrid
                getRowClassName={() => "payer-table-row"}
                getRowId={(row) => row.siteInsuranceName}
                rows={tableData}
                columns={tablecolumns}
                rowHeight={60}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
                pageSizeOptions={[10, 20, 50]}
                autoPageSize={false}
              />
            </GridItem>
          </GridContainer>
        </Box>
      )}
    </>
  );
}
export default PayersDashboard;
