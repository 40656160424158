import React from "react";
import { Typography, useTheme } from "@mui/material";
import { getPageNotFoundStyle } from "../../components/customstyles/PageNotFoundStyle";
const useStyles = () => {
  const theme = useTheme();
  return getPageNotFoundStyle(theme);
};

function PageNotFound() {
  const styles = useStyles();
  return (
    <div style={styles.textStyle}>
      <Typography>404! Page Not Found.</Typography>
    </div>
  );
}
export default PageNotFound;
