let twoDigits = (d) => {
  if (0 <= d && d < 10) return "0" + d.toString();
  if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
  return d.toString();
};
export const toMysqlFormat = (date) => {
  return (
    date.getUTCFullYear() +
    "-" +
    twoDigits(1 + date.getUTCMonth()) +
    "-" +
    twoDigits(date.getUTCDate())
  );
};

export const appointmentDateTimeFormatter = (datetime) => {
  const date = new Date(datetime);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
    .toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      year: "numeric",
    })
    .replace(/,\s\d{4}/, "");
};
