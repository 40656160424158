import React from "react";
import { useTheme, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { getPageNotFoundStyle } from "../../components/customstyles/PageNotFoundStyle";
const useStyles = () => {
  const theme = useTheme();
  return getPageNotFoundStyle(theme);
};

function ErrorPage() {
  const styles = useStyles();
  return (
    <>
      <div style={styles.textStyle}>
        <ErrorOutlineIcon sx={styles.bvPillIcon} />
        <Typography sx={styles.headingText}>
          Sorry, something went wrong!
        </Typography>
        <Typography sx={styles.subHeadingText}>
          There was an error loading this page. Please try again later.
        </Typography>
      </div>
    </>
  );
}
export default ErrorPage;
