import React from "react";
import { useTheme, Typography, Box } from "@mui/material";
import GridContainer from "./grid/GridContainer.jsx";
import GridItem from "./grid/GridItem.jsx";
import { searchInsuranceStyle } from "./customstyles/DashboardStyle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
const useStyles = () => {
  const theme = useTheme();
  return searchInsuranceStyle(theme);
};

function ResultNotFoundUI({ errorMessage }) {
  const styles = useStyles();
  return (
    <GridContainer sx={styles.gridCAlign}>
      <GridItem lg={12} md={12} sm={12} xs={12} sx={styles.gridAlign}>
        <Box sx={{ display: "flex" }}>
          <ErrorOutlineIcon />
          <Typography sx={styles.noResultTextStyle}>No Result</Typography>
        </Box>
      </GridItem>
      <GridItem lg={12} md={12} sm={12} xs={12}>
        <Typography sx={{ ...styles.subHeadingStyle, paddingLeft: "30px" }}>
          {errorMessage
            ? "Selected insurance plan data is not yet available, Please check later. It usually takes between 12-24 hrs to update."
            : "We were unable to find a match for the plan to run a preliminary check. You can run a preliminary coverage check for all insurance plans from the payer dashboard."}
        </Typography>
      </GridItem>
    </GridContainer>
  );
}
export default ResultNotFoundUI;
