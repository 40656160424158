import React from "react";
import { useTheme, Typography, Box, Stack } from "@mui/material";
import Button from "./customcomponents/CustomButton";
import GridContainer from "./grid/GridContainer";
import GridItem from "./grid/GridItem";
import MuiDataGrid from "./MuiDataGrid.jsx";
import { insuranceCoverageDetailsStyle } from "./customstyles/DashboardStyle";
import { drugNameMap } from "../utils/lookup";

const EmptyCell = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "inline-block",
        fontWeight: 500,
        scale: "4 1",
        translate: "100%",
        color: theme.palette.text.textFourthColor,
      }}
    >
      -
    </Box>
  );
};

const useStyles = (theme) => {
  return insuranceCoverageDetailsStyle(theme);
};

function IndicationsTable({ insuranceDrugData }) {
  const sortedInsuranceDrugData = React.useMemo(
    () =>
      [...insuranceDrugData]?.sort((a, b) =>
        b.drugName?.localeCompare(a.drugName)
      ),
    [insuranceDrugData]
  );
  const theme = useTheme();
  const styles = useStyles(theme);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const columnHeader = (params) => (
    <Typography sx={styles.tableLabelColor}>
      {params.colDef.headerName}
    </Typography>
  );

  const columnCell = (params) => (
    <Typography
      sx={{
        margin: "auto 0",
        padding: "15px 0",
        color: theme.textColour.emrTextColor,
        fontSize: "14px",
        lineHeight: "22px",
        letterSpacing: "0.5px",
        whiteSpace: "wrap",
      }}
    >
      {params.value ? params.value : <EmptyCell />}
    </Typography>
  );

  const commonColumnOptions = {
    aggregable: false,
    resizable: false,
    hideable: false,
    gropable: false,
    sortable: false,
    pinnable: false,
    renderHeader: columnHeader,
    renderCell: columnCell,
    flex: 1,
  };

  const columns = [
    {
      ...commonColumnOptions,
      field: "indication",
      headerName: "Indication",
    },
    {
      ...commonColumnOptions,
      field: "status",
      headerName: selectedIndex === 0 ? "EYLEA HD Status" : "EYLEA Status",
    },
    {
      ...commonColumnOptions,
      field: "stepEdits",
      headerName: "Step Edit Status",
    },
    {
      ...commonColumnOptions,
      field: "stepStatement",
      headerName: "Step Edit Description",
    },
    {
      ...commonColumnOptions,
      field: "PA",
      headerName: "PA Status",
      maxWidth: 95,
      renderCell: (params) => (
        <Typography
          sx={{
            margin: "auto 0",
            padding: "15px 0",
            color: theme.textColour.emrTextColor,
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.5px",
            whiteSpace: "wrap",
          }}
        >
          {params.value !== "" ? params.value ? "Yes" : "No" : <EmptyCell />}
        </Typography>
      ),
    },
  ];

  return (
    <GridContainer>
      <Stack
        direction={"row"}
        sx={{
          width: "100%",
          borderBottom: "1px solid",
          borderColor: "#e0e0e0",
          marginBottom: "10px",
        }}
      >
        {sortedInsuranceDrugData?.map((item, index) => {
          return (
            <div key={item.drugName}>
              <Button
                onClick={() => setSelectedIndex(index)}
                sx={
                  selectedIndex === index && {
                    ...styles.borderBottomStyle,
                    borderRadius: "0",
                  }
                }
                wrapperSx={{ margin: "0" }}
              >
                <Typography
                  sx={{ ...styles.drugNameStyle, padding: "5px 16px" }}
                >
                  {drugNameMap[item.drugName] ?? item.drugName}
                </Typography>
              </Button>
            </div>
          );
        })}
      </Stack>
      <GridItem>
        <MuiDataGrid
          rows={sortedInsuranceDrugData?.[selectedIndex]?.planDetails ?? []}
          getRowId={(row) => `${selectedIndex}_${row.indication}`}
          columns={columns}
          getRowHeight={() => "auto"}
          hideFooter={true}
        />
      </GridItem>
    </GridContainer>
  );
}

export default IndicationsTable;
