import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PageNotFound from "../views/public/PageNotFound";
import About from "../views/public/AboutPage";
import Patient from "../views/patients/Patient";
import LoginPage from "../views/public/LoginPage";
import PayersDashboard from "../views/payers/PayersDashboard";
import PayerDetails from "../views/payers/PayerDetails";
import TimeOutBackground from "../components/TimeOutBackground";
import SSOLoginPage from "../views/public/SSOLoginPage";
import ThankYouPage from "../views/public/ThankYouPage";
import { Box, useTheme } from "@mui/material";

const useStyle = () => {
  const theme = useTheme();
  return {
    rootGrid: {
      minHeight: "100vh",
      minWidth: "300px",
      display: "grid",
      gridTemplateRows: "auto 1fr auto",
      gridTemplateColumns: "100%",
      overflowX: "auto",
    },
    layoutContainer: {
      minWidth: "900px",
      width: "100%",
      margin: "0 auto",
      padding: { xs: "0 10px 40px 10px", md: "0 20px 70px 20px" },
      backgroundColor: theme.custom.colorWhite,
    },
  };
};

function AppLayout() {
  const styles = useStyle();
  return (
    <Box sx={styles.rootGrid}>
      <Navbar />
      <Box sx={styles.layoutContainer}>
        <Switch>
          <Route path="/payers/:siteInsuranceName" component={PayerDetails} />
          <Route path="/payers" component={PayersDashboard} />
          <Route path="/patients" component={Patient} />
          <Route path="/timeout" component={TimeOutBackground} />
          <Route path="/about" component={About} />
          <Route path="/login/:token" component={SSOLoginPage} />
          <Route path="/logout" component={ThankYouPage} />
          <Route path="/login" component={LoginPage} />
          <Route
            path="/"
            exact={true}
            render={() => <Redirect to="/login" />}
          />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </Box>
      <Footer />
    </Box>
  );
}

export default AppLayout;
