import React from "react";
import { Typography, Link, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

function Footer() {
  return (
    <Box
      sx={(theme) => ({
        padding: "10px 0",
        textAlign: "center",
        backgroundColor: theme.custom.colorWhite,
        width: "100%",
      })}
    >
      <Typography
        sx={(theme) => ({
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "20px",
          letterSpacing: "0.5px",
          color: theme.textColour.headingColour,
        })}
      >
        ©2023 Amalgam RX. All rights reserved.
        <br />
        <Link
          component={RouterLink}
          to="/about"
          underline="none"
          sx={(theme) => ({
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "20px",
            letterSpacing: "0.5px",
            marginTop: "10px",
            padding: "0 10px",
            color: theme.palette.primary.light,
            display: "inline-block",
          })}
        >
          About EYLEA-NOW
        </Link>
      </Typography>
    </Box>
  );
}
export default Footer;
