import React from "react";
import { useTheme, Typography, Box } from "@mui/material";
import logoutImage from "../../assets/images/logout.svg";
import { getPageNotFoundStyle } from "../../components/customstyles/PageNotFoundStyle";
const useStyles = () => {
  const theme = useTheme();
  return getPageNotFoundStyle(theme);
};

function ThankYouPage() {
  const styles = useStyles();
  return (
    <Box sx={styles.logoutPageStyle}>
      <img alt="logout icon" src={logoutImage} style={styles.logoutIcon} />
      <Typography sx={styles.headingText}>Your session has ended!</Typography>
      <Typography sx={styles.subHeadingText}>
        Please go to your patient's record in the EHR and select "EYLEA-NOW"{" "}
        <br />
        if you need to access EYLEA-NOW again.
      </Typography>
    </Box>
  );
}
export default ThankYouPage;
