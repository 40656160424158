import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { CircularProgress, Typography, useTheme, Box } from "@mui/material";
import GridContainer from "../../components/grid/GridContainer.jsx";
import GridItem from "../../components/grid/GridItem.jsx";
import { AuthContext } from "../../context/AuthContext";
import { getDashboardStyle } from "../../components/customstyles/DashboardStyle";
import backImage from "../../assets/images/arrow-back.svg";
import IndicationsTable from "../../components/IndicationsTable.jsx";
import ResultNotFoundUI from "../../components/ResultNotFoundUI.jsx";

const useStyles = () => {
  const theme = useTheme();
  return getDashboardStyle(theme);
};

function PayerDetails() {
  const styles = useStyles();
  const params = useParams();
  const siteInsuranceName = params.siteInsuranceName;
  const { makeAuthenticatedApiCall } = React.useContext(AuthContext);
  const [insuranceCoverage, setInsuranceCoverage] = React.useState();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const getPayerDetails = async () => {
      let response = await makeAuthenticatedApiCall(
        "get",
        `/rgnserver/api/insuranceplan/getinsurancecoveragedetails/${encodeURIComponent(
          siteInsuranceName
        )}`,
        {}
      );
      if (response?.status === 200) {
        setInsuranceCoverage(response.data);
      }
      setLoading(false);
    };
    getPayerDetails();
  }, []);

  return (
    <>
      <Helmet>
        <title>Payer details</title>
      </Helmet>
      {loading ? (
        <Box sx={styles.loadingLeftStyle}>
          <Box sx={{ marginBottom: "20px" }}>
            <CircularProgress />
          </Box>
          <Typography sx={styles.loadinText}>
            Loading payer details...
          </Typography>
        </Box>
      ) : (
        <Box sx={styles.tableStyle}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12} sx={styles.gridAlign}>
              <Box
                sx={styles.backBtn}
                onClick={() => {
                  window.history.back();
                }}
              >
                <img src={backImage} alt="back" height="20px" />
                <Typography sx={styles.backBtnText}>Back</Typography>
              </Box>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Typography sx={styles.headingStyle}>
                {siteInsuranceName}
              </Typography>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12} sx={styles.gridAlign}>
              {insuranceCoverage ? (
                <IndicationsTable
                  insuranceDrugData={insuranceCoverage?.drugs}
                />
              ) : (
                <ResultNotFoundUI errorMessage={true} />
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12} sx={styles.gridAlign}>
              <Typography sx={{ ...styles.guaranteeText, textAlign: "center" }}>
                All trademark and brand names are properties of their respective
                owners.
              </Typography>
            </GridItem>
          </GridContainer>
        </Box>
      )}
    </>
  );
}

export default PayerDetails;
