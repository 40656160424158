let getPageNotFoundStyle = (theme) => {
  return {
    tableStyle: {
      maxWidth: "1200px",
      margin: { xs: "0px auto", md: "0" },
      padding: "0",
    },
    textStyle: { fontSize: "14px", textAlign: "center", marginTop: "8%" },
    logoutPageStyle: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    headingText: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "32px",
      letterSpacing: "1px",
      textAlign: "center",
      color: theme.textColour.headingColour,
    },
    errorMessageStyle: {
      marginTop: "10px",
      marginBottom: "50px",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "22px",
      letterSpacing: "0.5px",
      textAlign: "center",
      color: theme.textColour.headingColour,
    },
    subHeadingText: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "22px",
      letterSpacing: ".5px",
      textAlign: "center",
      color: theme.textColour.headingColour,
    },
    bvPillIcon: { fontSize: "40px !important" },
    buttonStyle: {
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "1px",
      marginTop: "10px",
      padding: "5px 10px 5px 10px",
    },
    logoutIcon: {
      height: "45px",
      width: "50px",
      marginBottom: "25px",
    },
  };
};

export { getPageNotFoundStyle };
