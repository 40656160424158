const getDashboardObject = (theme) => {
  return {
    prescriptionLink: {
      color: "#2f363cc4",
      padding: "15px 20px",
      ":hover": { background: "#0000000a", color: "#00446b" },
    },
    payerViewDetailsBtn: {
      width: "max-content",
      cursor: "pointer",
      textDecoration: "none",
      fontSize: "14px",
      color: theme.textColour.primaryColor,
      fontWeight: 600,
      lineHeight: "24px",
      letterSpacing: "1px",
      padding: "6px",
    },
    addPatientButton: {
      padding: "9px 22px",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "1px",
      backgroundColor: theme.textColour.primaryColor,
    },
    patientHeading: {
      fontSize: "24px",
      fontWeight: 500,
      letterSpacing: "1px",
      lineHeight: "32px",
      color: theme.textColour.headingColour,
      margin: "20px 0",
    },
    payersHeading: {
      fontSize: "24px",
      fontWeight: 500,
      letterSpacing: "1px",
      lineHeight: "32px",
      color: theme.textColour.headingColour,
      marginTop: "20px",
    },
    payersSubheading: {
      fontSize: "14px",
      fontWeight: 500,
      letterSpacing: "0.5px",
      lineHeight: "12px",
      color: theme.textColour.textTertiary,
      marginBottom: "20px",
    },
    tableRow: {
      "&:hover": {
        backgroundColor: theme.palette.background.hovered,
      },
    },
    BVPill: {
      width: "fit-content",
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-evenly",
      alignItems: "center",
      padding: "8px 12px",
      height: "32px",
      color: theme.palette.accent3.main,
      fontWeight: 500,
      borderRadius: "18px",
      backgroundColor: theme.palette.accent3.background,
    },
    BVPillIcon: {
      marginRight: "2px",
      height: "16px",
    },
    BVStatusText: {
      alignSelf: "center",
      width: "max-content",
      fontSize: "10px",
      fontWeight: "600",
      lineHeight: "11.72px",
      letterSpacing: "0.4px",
    },
    TableBodyText: {
      color: theme.textColour.emrTextColor,
      fontSize: "14px",
      lineHeight: "22px",
      letterSpacing: "0.5px",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    TableBodyText2: {
      margin: "20px 0px",
      color: theme.textColour.emrTextColor,
      fontSize: "14px",
      lineHeight: "22px",
      letterSpacing: "0.5px",
    },
    TableBodySubText: {
      color: theme.textColour.headingColour,
      fontSize: "14px",
      lineHeight: "17.5px",
      letterSpacing: "0.25px",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    noAppointmentText: {
      color: theme.palette.text.textFourthColor,
      fontSize: "14px",
      lineHeight: "17.5px",
      letterSpacing: "0.25px",
    },
    patientEmrText: {
      color: theme.textColour.textTertiary,
      fontSize: "12px",
      lineHeight: "14.05px",
      letterSpacing: "0.4px",
    },
    noInsuranceText: {
      fontSize: "14px",
      lineHeight: "22px",
      letterSpacing: "0.5px",
      color: theme.palette.text.textFourthColor,
    },
    loadingLeftStyle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      transform: "translate(-50%, -50%)",
    },
    tableStyle: {
      maxWidth: "1400px",
      width: "100%",
      margin: "0px auto",
      padding: "0 20px",
    },
    gridCAlign: { alignItems: "center" },
    navBarIconStyle: {
      width: "18px",
      marginRight: "8px",
    },
    gridAlign: {
      alignItems: { xs: "left", sm: "center" },
      margin: "20px 0 15px",
    },
    headingStyle: {
      fontSize: "24px",
      color: theme.textColour.headingColour,
      fontWeight: 600,
      lineHeight: "32px",
      letterSpacing: "1px",
    },
    subHeadingStyle: {
      fontSize: "14px",
      color: theme.palette.text.textSecondaryColor,
      fontWeight: 400,
      lineHeight: "22px",
      letterSpacing: "0.5px",
    },
    inlineStyle: { display: "flex" },
    reloadinIconStyle: {
      fontSize: "14px",
      height: "18px",
      width: "16px",
      marginRight: "6px",
      marginTop: "3px",
    },
    tableLabelColor: {
      textTransform: "capitalize",
      margin: 0,
      color: theme.textColour.headingColour,
      fontSize: "14px",
      lineHeight: "22.2px",
      letterSpacing: ".1px",
      fontWeight: "bolder",
    },
    divButtonStyle: { textAlign: "right" },
    navbarLogoutStyle: {
      color: theme.custom.activeNavLink,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer",
      marginRight: "10px",
      float: "right",
      fontSize: "14px",
      fontWeight: 500,
      letterSpacing: 1,
      [theme.breakpoints.down("xs")]: { float: "left", padding: "0px" },
    },
    backButtonStyle: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "1px",
      color: theme.textColour.headingColour,
    },
    borderBottom: { borderBottom: "1px solid #ECEEF0" },
    btnGridAlign: {
      alignItems: "center",
      margin: "10px 0",
      paddingLeft: "0px !important",
    },
    textMarginLeft: {
      margin: "85px 16px 10px 16px",
      [theme.breakpoints.down("xs")]: { margin: "85px 10px 60px 10px" },
    },
    loadinText: {
      fontSize: "14px",
      color: theme.textColour.textTertiary,
      fontWeight: 400,
      lineHeight: "22px",
      letterSpacing: ".5px",
    },
    list: { width: 250 },
    fullList: { width: "auto" },
    menuButton: {
      padding: "12px",
      marginLeft: { xs: "10px" },
      height: 46,
      color: theme.custom.activeNavLink,
    },
    root: { flexGrow: 1 },
    dispBlk: {
      [theme.breakpoints.down("sm")]: { display: "block", width: "100%" },
    },
    appBar: {
      borderTop: `7px solid #FFD100`,
      borderBottom: "3px solid #ECEEF0",
      backgroundColor: "#fff",
      height: "89px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    backBtn: {
      cursor: "pointer",
      width: "65px",
      margin: "25px 20px 5px 0px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    backBtnText: {
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "1px",
      color: theme.textColour.primaryColor,
      fontWeight: 600,
    },
    guaranteeText: {
      fontWeight: 400,
      fontSize: "12px",
      color: theme.textColour.headingColour,
      lineHeight: "20px",
      letterSpacing: "0.5px",
      padding: "0 24px",
      [theme.breakpoints.down("xs")]: { padding: "0" },
    },
  };
};
let searchInsuranceObject = (theme) => {
  let searchInsurance = getDashboardObject(theme);
  searchInsurance.loadingInsuranceStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
  searchInsurance.paginationStyle = {
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: "4%",
    [theme.breakpoints.down("sm")]: { marginLeft: "0px", marginRight: "0px" },
  };
  searchInsurance.previousStyle = {
    fontSize: "4px",
    height: "60px",
    left: 0,
    position: "absolute",
    width: "150px",
  };
  searchInsurance.paginationPage = { fontWeight: "700" };
  searchInsurance.nextStyle = {
    fontSize: "4px",
    height: "60px",
    position: "absolute",
    right: 0,
    width: "150px",
  };
  searchInsurance.disabledItemStyle = { color: "#d3d3d3" };
  searchInsurance.activeItem = {
    border: "solid 1px #808e9b",
    height: "40px",
    width: "40px",
    borderRadius: "20px",
    color: "#fff",
    backgroundColor: "#00446B",
  };
  searchInsurance.paginationContainer = {
    display: "flex",
    flexDirection: "row",
    height: "40px",
    justifyContent: "center",
    listStyleType: "none",
    position: "relative",
  };
  searchInsurance.itemStyle = {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    fontSize: "14px",
    height: "40px",
    justifyContent: "center",
    width: "40px",
  };
  searchInsurance.inputItem = { width: "100%" };
  searchInsurance.headingStyle = {
    fontSize: "16px",
    color: theme.textColour.headingColour,
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing: "1px",
  };

  searchInsurance.gridAlign = { alignItems: "center", margin: "10px 0" };
  searchInsurance.reloadinIconStyle = {
    fontSize: "14px",
    height: "18px",
    width: "16px",
    marginRight: "6px",
    marginTop: "3px",
  };
  searchInsurance.buttonStyle = {
    width: "100%",
    display: "block",
    margin: 0,
  };
  searchInsurance.noResultTextStyle = {
    width: "100%",
    display: "block",
    margin: "0px 0px 0px 5px",
  };
  searchInsurance.searchButtonStyle = {
    marginLeft: "20px",
    width: "130px",
    padding: "13px 22px",
    [theme.breakpoints.down("sm")]: { marginLeft: "0px" },
  };
  searchInsurance.tableStyle = {
    maxWidth: "900px",
    margin: "0 auto",
    padding: "0",
  };
  searchInsurance.selectButtonStyle = {
    fontSize: "14px",
    color: theme.textColour.primaryColor,
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "1px",
    padding: "6px",
  };
  return searchInsurance;
};
const insuranceCoverageDetailsObject = (theme) => {
  let insuranceCoverageObject = getDashboardObject(theme);
  insuranceCoverageObject.displayFlex = { display: "flex" };
  insuranceCoverageObject.borderBottomStyle = {
    borderBottom: "solid 2px #00446B",
  };
  insuranceCoverageObject.drugNameStyle = {
    color: theme.textColour.headingColour,
    paddingLeft: "16px",
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "1.25px",
  };
  insuranceCoverageObject.drugNameDisabledStyle = {
    color: "rgba(5, 6, 6, 0.38)",
    fontSize: "14px",
    paddingLeft: "16px",
    fontWeight: 600,
    letterSpacing: "1.25px",
  };

  insuranceCoverageObject.dialogHeading = {
    fontSize: "18px",
    color: theme.textColour.headingColour,
    lineHeight: "21.09px",
    letterSpacing: "0.15px",
    fontWeight: 600,
  };
  insuranceCoverageObject.dialogBodytext = {
    textAlign: "justify",
    fontSize: "14px",
    color: theme.textColour.dialogBodytext,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    fontWeight: 400,
  };
  insuranceCoverageObject.dialogbtnStyle = {
    margin: "5px",
    cursor: "pointer",
    fontSize: "14px",
    color: theme.textColour.primaryColor,
    lineHeight: "24px",
    letterSpacing: "1px",
    fontWeight: 600,
  };
  insuranceCoverageObject.addResumeButton = {
    backgroundColor: "rgba(0, 68, 107, 0.08)",
    borderRadius: "6px",
    alignItems: "center",
    alignSelf: "center",
    // gap: "8px",
    display: "flex",
    cursor: "pointer",
    marginLeft: "21px",
  };
  insuranceCoverageObject.btnStyes = { padding: "6px 8px" };
  insuranceCoverageObject.emrStyle = {
    letterSpacing: "0.5px",
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: 400,
    color: theme.textColour.emrTextColor,
    alignSelf: "stretch",
  };
  insuranceCoverageObject.styleFlex = {
    marginLeft: "16px",
    marginBottom: "10px",
    display: "flex",
  };
  insuranceCoverageObject.medbvStatusStyle = {
    display: "flex",
    // width: "100%",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "16px",
    padding: "8px 12px 8px 12px",
    margin: "8.2px 8px",
    backgroundColor: theme.btnColour.btnSecondaryBackground,
    cursor: "text",
  };
  insuranceCoverageObject.medbvStatusIconStyle = {
    marginRight: "5px",
    height: "16px",
  };
  insuranceCoverageObject.btnTextStyle = {
    fontSize: "10px",
    lineHeight: "14.06px",
    fontWeight: 600,
    letterSpacing: "0.4px",
    textTransform: "uppercase",
    color: theme.palette.accent3.main,
  };
  insuranceCoverageObject.root = { marginBottom: "25px" };
  insuranceCoverageObject.marginTop = { marginTop: "20px" };
  insuranceCoverageObject.loginButton = {
    color: theme.btnColour.btnTextColour,
    backgroundColor: theme.btnColour.btnBackgroundColour,
    border: "1px solid " + theme.btnColour.btnBackgroundColour,
    borderRadius: "50px",
    margin: "8px 0",
    textAlign: "right",
    padding: "7px 15px",
    "&:hover": {
      backgroundColor: theme.btnColour.btnBackgroundColour,
      color: theme.btnColour.btnTextColour,
      border: "1px solid " + theme.btnColour.btnBackgroundColour,
      opacity: "0.8",
    },
  };
  insuranceCoverageObject.loadingCoverageStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };
  insuranceCoverageObject.textStyle = {
    fontSize: "14px",
    color: theme.textColour.textTertiary,
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0.5px",
  };
  insuranceCoverageObject.medBVUIStyle = {
    maxWidth: "1300px",
    padding: "0",
    margin: "0px auto 60px",
    [theme.breakpoints.down("sm")]: { margin: "0px auto 60px" },
  };
  insuranceCoverageObject.tableStyle = {
    maxWidth: "900px",
    margin: "0 auto",
    padding: "0",
  };

  insuranceCoverageObject.gridAlign = {
    alignItems: "center",
    margin: "24px 0",
  };
  insuranceCoverageObject.gridAlignCenter = {
    alignItems: "center",
    alignSelf: "center",
    paddingTop: "30px",
  };
  insuranceCoverageObject.paddingBottom = {
    alignItems: "center",
    padding: "0 0 32px 0 ",
  };
  insuranceCoverageObject.cardStyle = {
    width: "100%",
    backgroundColor: "#ffffff",
    marginBottom: "15px",
    border: "1px solid #ECEEF0",
    borderRadius: "12px",
    boxShadow:
      " 0px 3px 3px -2px rgba(48, 55, 59, 0.0012), 0px 3px 4px rgba(48, 55, 59, 0.14), 0px 1px 8px rgba(48, 55, 59, 0.002);",
    padding: "0 24px",
    [theme.breakpoints.down("xs")]: { padding: "5px" },
  };
  insuranceCoverageObject.cardTextStyle = {
    fontSize: "14px",
    color: theme.textColour.textTertiary,
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.5px",
  };
  insuranceCoverageObject.cardTextValueStyle = {
    fontSize: "14px",
    color: theme.textColour.headingColour,
    lineHeight: "16.51px",
    letterSpacing: "0.1px",
    fontWeight: 600,
    textAlign: "right",
  };
  insuranceCoverageObject.paTextStyle = {
    margin: "0px !important",
    color: theme.textColour.primaryColor,
  };
  insuranceCoverageObject.paGrid = {
    textAlign: "center",
    backgroundColor: "#2C919914",
    borderRadius: "25px",
    margin: "0px !important",
    padding: "5px",
    [theme.breakpoints.down("sm")]: { margin: "5px !important" },
  };
  insuranceCoverageObject.investigationText = {
    marginLeft: "10px",
    fontSize: "16px",
    color: theme.textColour.headingColour,
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.15px",
  };
  insuranceCoverageObject.changeLink = {
    width: "max-content",
    cursor: "pointer",
    textDecoration: "none",
    fontSize: "14px",
    color: theme.textColour.primaryColor,
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "1px",
    padding: "6px",
  };
  insuranceCoverageObject.nextLinkStyle = {
    textAlign: "end",
    cursor: "pointer",
  };
  insuranceCoverageObject.searchPlanBtn = {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "1px",
    color: theme.textColour.primaryColor,
    padding: "6px",
  };
  insuranceCoverageObject.searchPlanText = {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    color: theme.textColour.headingColour,
  };
  insuranceCoverageObject.benefitTextStyle = {
    cursor: "pointer",
    fontSize: "14px",
    color: theme.textColour.primaryColor,
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "1px",
  };
  insuranceCoverageObject.prelimniryText = {
    fontSize: "14px",
    color: theme.textColour.headingColour,
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0.5px",
  };
  insuranceCoverageObject.gridItemAlign = {
    textAlign: "justify",
    margin: "14px 20px 0 20px",
    display: "flex",
    [theme.breakpoints.down("xs")]: { margin: "14px 5px 0 5px" },
  };
  insuranceCoverageObject.headingImage = {
    height: "30px",
    width: "30px",
    flexGrow: 0,
    flex: "none",
    borderRadius: "1000px",
    padding: "6px",
    backgroundColor: "rgba(0, 68, 107, 0.08)",
  };
  insuranceCoverageObject.imageStyle = {
    paddingLeft: "2px",
  };
  insuranceCoverageObject.backgroundStyle = {
    height: "82px",
    backgroundColor: "rgba(0, 68, 107, 0.08)",
    borderRadius: "6px",
    padding: "0px 16px 0px 16px",
    margin: "24px 0",
  };
  insuranceCoverageObject.alignItemRight = {
    padding: "0px 0px 0px 82px",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      padding: "0px 0px 0px 65px",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      padding: "0px 0px 0px 0px",
    },
  };

  insuranceCoverageObject.errorTableStyle = {
    maxWidth: "900px",
    margin: "40px auto 0px",
    padding: "0",
    [theme.breakpoints.down("sm")]: { margin: "0px auto 60px" },
  };

  insuranceCoverageObject.backBtn = {
    cursor: "pointer",
    width: "65px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  };

  insuranceCoverageObject.backBtnText = {
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "1px",
    color: theme.textColour.primaryColor,
    fontWeight: 600,
  };
  return insuranceCoverageObject;
};

export const getDashboardStyle = getDashboardObject;
export const searchInsuranceStyle = searchInsuranceObject;
export const insuranceCoverageDetailsStyle = insuranceCoverageDetailsObject;
