import React, { useState, useRef, useContext } from "react";
import {
  Card,
  Typography,
  InputAdornment,
  Box,
  Link,
  Stack,
  useTheme,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import Button from "../../components/customcomponents/CustomButton";
import FormikTextField from "../../components/formikcomponent/TextField";
import { Helmet } from "react-helmet";
import { Formik, Field, Form } from "formik";
import { string, object } from "yup";
import EditIcon from "@mui/icons-material/Edit";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";

const useStyles = () => {
  const theme = useTheme();
  return {
    container: {
      height: "100%",
      width: "100%",
    },
    card: {
      padding: "40px",
      borderRadius: "10px",
      marginTop: "25px",
      width: { xs: "100%", sm: "600px" },
    },
    cardTitle: {
      fontSize: "22px",
      color: "#0D3C61",
      fontWeight: 500,
      lineHeight: "29px",
      letterSpacing: "0.5px",
    },
    cardSubTitle: {
      fontSize: "14px",
      color: "#2F363CDE",
      lineHeight: "22px",
      letterSpacing: "0.5px",
    },
    inputButton: { cursor: "pointer" },
    sendOtpText: {
      color: "#2F363CC4",
      fontSize: "14px",
      letterSpacing: "0.5px",
    },
    errorText: {
      color: theme.palette.error.main,
      fontSize: "12px",
    },
    continueButton: {
      padding: "13px",
      backgroundColor: theme.textColour.primaryColor,
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "24px",
      letterSpacing: "1px",
    },
  };
};

const OTP_COUNTER_TIMEOUT_IN_SECONDS = 60;
const MAX_CHARS_IN_EMAIL = 78;

function LoginPage() {
  const styles = useStyles();
  const history = useHistory();
  const { changeUser } = useContext(AuthContext);
  const [resendOTPCounter, setResendOTPCounter] = useState(
    OTP_COUNTER_TIMEOUT_IN_SECONDS
  );
  const resendOTPCouterIntervalRef = useRef();
  const [OTPToken, setOTPToken] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);
  const [resendOtpLoading, setResendOtpLoading] = useState(false);
  const [displayOtpScreen, setDisplayOtpScreen] = useState(false);

  const yupSchema = object({
    useEmailId: string()
      .trim()
      .max(MAX_CHARS_IN_EMAIL, "Email length too long.")
      .required("This field is required.")
      .email("Please enter a valid email id."),
    otp: string(),
  });

  const restartResendOTPCounter = () => {
    // clear previous interval if any
    if (resendOTPCouterIntervalRef.current) {
      clearInterval(resendOTPCouterIntervalRef.current);
    }
    setResendOTPCounter(OTP_COUNTER_TIMEOUT_IN_SECONDS);
    resendOTPCouterIntervalRef.current = setInterval(() => {
      setResendOTPCounter((prevCounter) => {
        if (prevCounter === 0) {
          clearInterval(resendOTPCouterIntervalRef.current);
          resendOTPCouterIntervalRef.current = null;
          return 0;
        } else {
          return prevCounter - 1;
        }
      });
    }, 1000);
  };

  const getOtpFromAPI = async (emailId, formHelper) => {
    setResendOtpLoading(true);
    // call the send otp API
    try {
      const response = await axios({
        method: "post",
        url: "/rgnserver/api/userauthservice/sendotp",
        data: { emailId },
      });
      if (response?.status === 200 && response.data.token) {
        setOTPToken(response.data.token);
        restartResendOTPCounter();
        setDisplayOtpScreen(true);
      }
    } catch (err) {
      if (err.response?.status === 400) {
        formHelper.setFieldError(
          "useEmailId",
          "Email address is not authorized."
        );
      } else {
        // just log any other error
        console.log("[send otp error]", err);
      }
    }
    setResendOtpLoading(false);
  };

  const verifyOtp = async (emailId, otp, formHelper) => {
    // call the send otp API
    try {
      const response = await axios({
        method: "post",
        url: "/rgnserver/api/userauthservice/verifyotp",
        data: { emailId, otp, token: OTPToken },
      });
      if (response?.status === 200) {
        localStorage.setItem("refreshToken", response.data.refreshToken);
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("userId", response.data.userid);
        localStorage.setItem("loginBy", "email");
        await changeUser({ userid: response.data.userid });
        history.push("/patients");
      }
    } catch (err) {
      if (err.response?.status === 422) {
        formHelper.setFieldError("otp", "Email or OTP is incorrect.");
      } else if (err.response?.status === 400) {
        if (err.response?.data?.errorCode === 1) {
          formHelper.setFieldError("otp", "Email or OTP is incorrect.");
        } else if (err.response?.data?.errorCode === 2) {
          formHelper.setFieldError(
            "otp",
            "OTP has expired. Please click on Re-send email."
          );
        } else {
          formHelper.setFieldError("otp", "Something went wrong");
        }
      } else {
        // just log any other error
        console.log("[verify otp error]", err);
      }
    }
  };
  const handleSubmit = async (values, formHelper) => {
    setLoginLoading(true);
    values.useEmailId = values.useEmailId.trim();
    values.otp = values.otp.trim();
    if (displayOtpScreen) {
      await verifyOtp(values.useEmailId, values.otp, formHelper);
    } else {
      await getOtpFromAPI(values.useEmailId, formHelper);
    }
    setLoginLoading(false);
  };
  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Stack alignItems="center" justifyContent="center" sx={styles.container}>
        <Card raised={true} sx={styles.card}>
          <Stack spacing={4}>
            <Stack spacing={1}>
              <Typography sx={styles.cardTitle}>Log in to EYLEA-NOW</Typography>
              <Typography sx={styles.cardSubTitle}>
                Real-time access to payer policies, electronic E4U enrollment,
                and more.
              </Typography>
            </Stack>
            <Formik
              initialValues={{ useEmailId: "", otp: "" }}
              onSubmit={handleSubmit}
              validationSchema={yupSchema}
            >
              {(props) => (
                <Form>
                  <Stack spacing={4}>
                    <Box>
                      <Field
                        name="useEmailId"
                        sx={{ width: "100%" }}
                        variant="outlined"
                        label="Email"
                        component={FormikTextField}
                        InputLabelProps={{ disabled: displayOtpScreen }}
                        InputProps={{
                          disabled: displayOtpScreen,
                          style: {
                            backgroundColor: "#fff",
                            minHeight: "47px",
                            fontSize: "14px",
                          },
                          endAdornment: displayOtpScreen && (
                            <InputAdornment position="end">
                              <EditIcon
                                onClick={() => {
                                  props.setFieldValue("otp", "");
                                  props.setFieldTouched("otp", false);
                                  setOTPToken(null);
                                  setDisplayOtpScreen(false);
                                }}
                                sx={styles.inputButton}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    {displayOtpScreen && (
                      <>
                        <Stack spacing={1}>
                          <Box>
                            <Field
                              validate={(value) => {
                                if (displayOtpScreen && value.trim() === "") {
                                  return "This field is required.";
                                } else {
                                  return null;
                                }
                              }}
                              component={FormikTextField}
                              variant="outlined"
                              label="One-Time Password (OTP)"
                              id="otp"
                              fullWidth
                              name="otp"
                              placeholder="OTP"
                              autoComplete="off"
                              InputProps={{
                                type: "text",
                                sx: styles.ieRemoveIcon,
                              }}
                            />
                          </Box>
                          <Typography sx={styles.sendOtpText}>
                            A one-time password has been emailed to you. If you
                            don't see it, please check your spam folder.
                          </Typography>
                          {resendOTPCounter === 0 ? (
                            <Box>
                              <Link
                                color="#2196F3"
                                sx={{ cursor: "pointer", width: "auto" }}
                                underline="hover"
                                onClick={async () => {
                                  await getOtpFromAPI(props.values.useEmailId);
                                }}
                                disabled={
                                  resendOTPCounter !== 0 ||
                                  props.isSubmitting ||
                                  resendOtpLoading
                                }
                              >
                                <Typography
                                  sx={{
                                    display: "inline",
                                    fontSize: "14px",
                                    letterSpacing: "0.5px",
                                  }}
                                >
                                  Re-send email.
                                </Typography>
                              </Link>
                            </Box>
                          ) : (
                            <Typography sx={styles.sendOtpText}>
                              You can re-send an email with your OTP in{" "}
                              {resendOTPCounter} second(s)
                            </Typography>
                          )}
                        </Stack>
                      </>
                    )}
                    <Stack spacing={1}>
                      <Button
                        variant="contained"
                        fullWidth={true}
                        loading={loginLoading}
                        disabled={
                          props.isSubmitting ||
                          resendOtpLoading ||
                          (displayOtpScreen &&
                            props.values.otp.trim() === "") ||
                          (!displayOtpScreen &&
                            props.values.useEmailId.trim() === "") ||
                          !props.isValid
                        }
                        type="submit"
                        wrapperSx={{ margin: 0 }}
                        sx={styles.continueButton}
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          Continue
                        </Typography>
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Stack>
        </Card>
      </Stack>
    </>
  );
}
export default LoginPage;
