import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  useTheme,
  CircularProgress,
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  Stack,
} from "@mui/material";
import GridContainer from "../../components/grid/GridContainer.jsx";
import GridItem from "../../components/grid/GridItem.jsx";
import { searchInsuranceStyle } from "../../components/customstyles/DashboardStyle";
import Button from "../../components/customcomponents/CustomButton";
import { Formik, Field, Form } from "formik";
import backImage from "../../assets/images/arrow-back.svg";
import { AuthContext } from "../../context/AuthContext";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { string, object } from "yup";
import FormikTextField from "../../components/formikcomponent/TextField";

const useStyles = () => {
  const theme = useTheme();
  return searchInsuranceStyle(theme);
};

function formatDate(dateString) {
  const [year, month, day] = dateString.split("-");
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
}

const PATIENT_MMR_MAX_LEN = 16;
const SOMETHING_WENT_WRONG = "Sorry, something went wrong!";

function PatientAdd() {
  const styles = useStyles();
  const [currentPatientData, setCurrentPatientData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const { makeAuthenticatedApiCall } = useContext(AuthContext);

  const handleSubmit = async (values) => {
    setErrorState(false);
    setLoading(true);
    let response = await makeAuthenticatedApiCall(
      "get",
      `/rgnserver/api/insuranceplan/getPatientDetailsByEHRID/${values.patientId}`
    );
    if (response?.status === 200 && response.data) {
      setCurrentPatientData(response.data);
    } else if (response?.status === 400) {
      setCurrentPatientData(null);
      if (response.data.errorCode === 1) {
        setErrorTitle(SOMETHING_WENT_WRONG);
        setErrorMessage("Error getting patient details");
        setErrorState(true);
      } else if (response.data.errorCode === 2) {
        setErrorTitle("No patients found");
        setErrorMessage(
          "We found no patients with this MRN. Please double-check the patient’s MRN and try again."
        );
        setErrorState(true);
      } else {
        console.log("[error search patient unhandled errorCode]", response);
        setErrorTitle(SOMETHING_WENT_WRONG);
        setErrorMessage(
          "There was an error loading this page. Please try again later."
        );
        setErrorState(true);
      }
    } else {
      console.log("[error search patient]", response);
    }
    setLoading(false);
  };
  return (
    <Box sx={{ ...styles.tableStyle, height: "100%" }}>
      <GridContainer>
        <Box
          sx={styles.backBtn}
          onClick={() => {
            history.goBack();
          }}
        >
          <img src={backImage} alt="back" height="20px" />
          <Typography sx={styles.backBtnText}>Back</Typography>
        </Box>
        <GridItem lg={12} md={12} sm={12} xs={12} sx={styles.gridAlign}>
          <span style={styles.headingStyle}>Find Patients to Add</span>
          <Typography sx={styles.subHeadingStyle}>
            Please input a patient’s medical record number (MRN) to add them to
            EYLEA-NOW.
          </Typography>
          <br />
          <Formik
            initialValues={{ patientId: "" }}
            validationSchema={object({
              patientId: string()
                .max(PATIENT_MMR_MAX_LEN, "Invalid patient MRN.")
                .matches(/^[a-zA-Z0-9]+$/, "Invalid patient MRN.")
                .required("This field is required."),
            })}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form>
                <GridContainer sx={styles.inlineStyle}>
                  <GridItem
                    lg={10}
                    md={10}
                    sm={12}
                    xs={12}
                    sx={styles.gridAlign}
                  >
                    <Field
                      name="patientId"
                      style={styles.inputItem}
                      variant="outlined"
                      label="Patient's MRN"
                      component={FormikTextField}
                      onChange={() => setErrorState(false)}
                      InputProps={{
                        style: {
                          backgroundColor: "#fff",
                          minHeight: "47px",
                          fontSize: "14px",
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem lg={2} md={2} sm={12} xs={12} sx={styles.gridAlign}>
                    <Button
                      primary
                      disabled={props.values.patientId.trim() === ""}
                      type="submit"
                      wrapperSx={styles.buttonStyle}
                      sx={styles.searchButtonStyle}
                    >
                      Find Patient
                    </Button>
                  </GridItem>
                </GridContainer>
              </Form>
            )}
          </Formik>
        </GridItem>
      </GridContainer>
      {loading && (
        <Box sx={{ ...styles.loadingInsuranceStyle, top: "60%" }}>
          <Box sx={{ marginBottom: "20px" }}>
            <CircularProgress sx={{ color: "#00446B" }} />
          </Box>
          <Typography sx={styles.loadinText}>Loading...</Typography>
        </Box>
      )}
      {errorState && (
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={3}
          sx={{ height: "300px" }}
        >
          {errorMessage === SOMETHING_WENT_WRONG ? (
            <ErrorOutlineIcon sx={{ width: "80px", height: "80px" }} />
          ) : (
            <HelpOutlineIcon sx={{ width: "80px", height: "80px" }} />
          )}
          <Stack justifyContent="center" alignItems="center" spacing={1}>
            <Typography sx={{ fontWeight: 600, textAlign: "center" }}>
              {errorTitle}
            </Typography>
            <Typography
              sx={{
                ...styles.subHeadingStyle,
                textAlign: "center",
                padding: "0 20px",
              }}
            >
              {errorMessage}
            </Typography>
          </Stack>
        </Stack>
      )}
      {currentPatientData && (
        <List>
          <div>
            <ListItem>
              <GridContainer sx={styles.gridCAlign}>
                <GridItem lg={10} md={10} sm={10} xs={8} sx={styles.gridAlign}>
                  <Typography sx={styles.subHeadingStyle}>
                    {currentPatientData.patientName} &bull;{" "}
                    {formatDate(currentPatientData?.birthdate)}
                  </Typography>
                  <Typography
                    sx={(theme) => ({
                      ...styles.subHeadingStyle,
                      color: theme.textColour.textTertiary,
                    })}
                  >
                    {currentPatientData.emrPatientId}
                  </Typography>
                </GridItem>
                <GridItem
                  lg={2}
                  md={2}
                  sm={2}
                  xs={4}
                  sx={{ textAlign: "right" }}
                >
                  <Button
                    sx={styles.selectButtonStyle}
                    onClick={() => {
                      history.push(
                        `/patients/insurancecoverage/${currentPatientData.patientId}?addBy=add`
                      );
                    }}
                  >
                    {currentPatientData?.existsInOurSystem
                      ? "VIEW PATIENT"
                      : "ADD PATIENT"}
                  </Button>
                </GridItem>
              </GridContainer>
            </ListItem>
            <Divider />
          </div>
        </List>
      )}
    </Box>
  );
}
export default PatientAdd;
