import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import RenderAppBasedOnInternet from "./RenderAppBasedOnInternet";
import axios from "axios";
import { Amplify, Analytics } from "aws-amplify";
import {
  awsPinPointConfig,
  sessionConfig,
  pageOptions,
  initialisePinPointConfig,
} from "./pinpointConfig";

const root = ReactDOM.createRoot(document.getElementById("root"));

let getConfig = async () => {
  let result = await axios.get("/rgnserver/api/userauthservice/getconfig");
  initialisePinPointConfig(
    result.data.pinpoint.idpId,
    result.data.pinpoint.region,
    result.data.pinpoint.appId
  );
  Amplify.configure(awsPinPointConfig()["Auth"]);
  Amplify.configure(awsPinPointConfig()["Analytics"]);
  Analytics.autoTrack("session", sessionConfig);
  Analytics.autoTrack("pageView", pageOptions);
  return result.data;
};

getConfig().then(
  root.render(
    <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
      <RenderAppBasedOnInternet />
    </BrowserRouter>
  )
);
