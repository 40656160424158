import React from "react";
import { AppointmentProvider } from "./context/AppointmentContext";
import { AuthProvider } from "./context/AuthContext";
import { Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import AppLayout from "./layouts/AppLayout";

import theme from "./ThemeFile";
import { ThemeProvider } from "@mui/material";
function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Regeneron Portal</title>
        <link rel="icon" type="image/png" href="" />
      </Helmet>

      <AppointmentProvider>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <Switch>
              <Route path="*" component={AppLayout} />
            </Switch>
          </AuthProvider>
        </ThemeProvider>
      </AppointmentProvider>
    </>
  );
}
export default App;
