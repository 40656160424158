import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  useTheme,
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  Button,
} from "@mui/material";
import GridContainer from "../../components/grid/GridContainer.jsx";
import GridItem from "../../components/grid/GridItem.jsx";
import benefitInvestigation from "../../assets/images/benefitInvestigation.svg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { insuranceCoverageDetailsStyle } from "../../components/customstyles/DashboardStyle";
const useStyles = () => {
  const theme = useTheme();
  return insuranceCoverageDetailsStyle(theme);
};

function BenefitsVerificationUI() {
  const styles = useStyles();
  let history = useHistory();
  const match = useRouteMatch();
  const handleBeneFitsVerificationClick = () => {
    history.push({
      pathname: `./${match.params.patientId}/processtransaction/0`,
    });
  };
  return (
    <GridContainer sx={styles.gridCAlign}>
      <Card sx={styles.cardStyle} variant="outlined">
        <CardContent>
          <GridContainer sx={styles.gridCAlign}>
            <GridItem lg={12} md={12} sm={12} xs={12} sx={styles.gridAlign}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={styles.headingImage}>
                  <img
                    src={benefitInvestigation}
                    style={styles.imageStyle}
                    alt="logout"
                  />
                </Box>
                <Typography sx={styles.investigationText}>
                  Benefits Verification
                </Typography>
              </Box>
            </GridItem>
          </GridContainer>
          <Button
            sx={{ width: "100%" }}
            onClick={() => {
              handleBeneFitsVerificationClick();
            }}
          >
            <Stack
              sx={{ width: "100%", padding: "5px" }}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Typography
                sx={{ ...styles.benefitTextStyle, marginLeft: "5px" }}
              >
                Start Benefits Verification via E4U
              </Typography>
              <NavigateNextIcon sx={styles.nextLinkStyle} alt="logout" />
            </Stack>
          </Button>
          <Box sx={{ content: "''", height: "24px" }} />
        </CardContent>
      </Card>
      <br></br>
    </GridContainer>
  );
}
export default BenefitsVerificationUI;
