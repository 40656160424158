import React, { useContext, useEffect, useState } from "react";
import { useTheme, CircularProgress, Typography, Box } from "@mui/material";
import ErrorPage from "./ErrorPage";
import PatientDetails from "../PatientDetails/PatientDetails";
import { useHistory, useRouteMatch } from "react-router-dom";
import IframeResizer from "iframe-resizer-react";
import IframeResizerJs from "../../assets/IframeResizerJs";
import { insuranceCoverageDetailsStyle } from "../../components/customstyles/DashboardStyle";
import { AuthContext } from "../../context/AuthContext";
import backImage from "../../assets/images/arrow-back.svg";
const useStyles = (theme) => {
  return insuranceCoverageDetailsStyle(theme);
};

function CareMetxMedBV({ loadingText }) {
  const theme = useTheme();
  const styles = useStyles(theme);
  const delayTime = 2000;
  const [iframeurl, setIframeurl] = useState(null);
  const [loadingStarted, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [patientName, setPatientName] = useState(null);
  const [patientEmrId, setPatientEmrId] = useState(null);
  const history = useHistory();
  const match = useRouteMatch();
  const { makeAuthenticatedApiCall } = useContext(AuthContext);
  const handleGetPatientDetails = async () => {
    let patientDetails = await makeAuthenticatedApiCall(
      "get",
      `/rgnserver/api/insuranceplan/getpatientdetails/${match.params.patientId}`,
      {}
    );
    if (patientDetails?.status === 200) {
      let patientData = patientDetails.data;
      setLoading(false);
      setPatientName(patientData.patientName);
      setPatientEmrId(patientData.emrPatientId);
    }
  };
  const handleInitiateMedBVApi = async (forceEnrollment = 0) => {
    let enrollmentDetails = await makeAuthenticatedApiCall(
      "post",
      `/rgnserver/api/caremetx/initiateMedBV/${match.params.patientId}/${forceEnrollment}/${match.params.useFromEHRFlag}`,
      {}
    );
    if (enrollmentDetails?.status === 200) {
      if (enrollmentDetails.data.errorDetails) {
        history.push({
          pathname: `../../../insurancecoverage/${match.params.patientId}`,
        });
      } else {
        if (enrollmentDetails.data.transactionType === "medebv") {
          history.push({
            pathname: `../../../insurancecoverage/${match.params.patientId}`,
          });
        } else {
          let htmlData = enrollmentDetails.data.HTMLContent;
          let IframeResizerScript = `<script type="text/javascript">${IframeResizerJs.data}</script>`;
          let updatedHtmlContent = htmlData.replace(
            "</div>\n</body>",
            `${IframeResizerScript}</div>\n</body>`
          );
          setLoading(false);
          setIframeurl(updatedHtmlContent);
        }
      }
    } else {
      setIframeurl(null);
      setLoading(false);
      setIsError(true);
    }
  };

  const handleWindowEvent = async (event) => {
    let eventData = event.data;
    if (
      eventData.message === "next clicked" &&
      eventData.type === "button-click"
    ) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    if (
      event.origin === window.origin &&
      eventData.eventName === "Submit-Successful"
    ) {
      if (eventData.transactionType === "Enrollment") {
        setLoading(true);
      }
      let updateResult = await makeAuthenticatedApiCall(
        "post",
        `/rgnserver/api/caremetx/updateTransaction/${eventData.transactionId}`,
        { status: eventData.status }
      );
      if (
        updateResult?.status === 200 &&
        eventData.transactionType === "Enrollment"
      ) {
        setTimeout(handleInitiateMedBVApi, delayTime);
      } else {
        setIframeurl(null);
        setLoading(false);
        setIsError(true);
      }
    } else if (eventData.eventName === "Cancel") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      history.push({ pathname: `../../../../patients` });
    }
  };

  useEffect(() => {
    let forceEnrollmentTrue = 1;
    window.addEventListener("message", handleWindowEvent);
    handleGetPatientDetails();
    handleInitiateMedBVApi(forceEnrollmentTrue);
    return () => {
      window.removeEventListener("message", handleWindowEvent);
    };
  }, []);

  return loadingStarted ? (
    <Box sx={styles.loadingCoverageStyle}>
      <Box sx={{ marginBottom: "20px" }}>
        <CircularProgress />
      </Box>
      <Typography sx={styles.loadinText}>{loadingText}</Typography>
    </Box>
  ) : (
    <>
      {isError && iframeurl === null && (
        <Box sx={styles.errorTableStyle}>
          <Box
            sx={styles.backBtn}
            onClick={() => {
              window.history.back();
            }}
          >
            <img src={backImage} alt="back" height="20px" />
            <Typography sx={styles.backBtnText}>Back</Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          overflowX: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minWidth: "1140px",
          padding: "0px 20px",
        }}
      >
        <PatientDetails
          patientEmrId={patientEmrId}
          patientName={patientName}
          showResubmit={false}
        />
        {isError && iframeurl === null ? (
          <ErrorPage />
        ) : (
          <IframeResizer
            checkOrigin={false}
            srcDoc={iframeurl}
            log={false}
            style={{
              width: "100%",
              minWidth: "1100px",
              minHeight: "400px",
              border: `2px solid ${theme.custom.iFrameBorder}`,
              borderRadius: "5px",
              backgroundColor: "white",
            }}
          />
        )}
      </Box>
    </>
  );
}
export default CareMetxMedBV;
