import React, { useContext, useEffect, useState } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import axios from "axios";
import { useTheme, CircularProgress, Typography, Box } from "@mui/material";
import { getPageNotFoundStyle } from "../../components/customstyles/PageNotFoundStyle";
import { AuthContext } from "../../context/AuthContext";
const useStyles = () => {
  const theme = useTheme();
  return getPageNotFoundStyle(theme);
};

function LoginPage() {
  const styles = useStyles();
  const [renderto, setRenderto] = useState("/logout");
  const [loadingCompleted, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const { changeUser } = useContext(AuthContext);
  const match = useRouteMatch();
  const setRefreshToken = (data) => {
    setRenderto(`/patients/insurancecoverage/${data.patientId}`);
    setLoading(true);
    localStorage.setItem("accessToken", data.accessToken);
    localStorage.setItem("refreshToken", data.refreshToken);
  };

  useEffect(() => {
    const getAccessToken = async () => {
      let response;
      try {
        let token = match.params.token;
        response = await axios.post("/rgnserver/api/userauthservice/login", {
          token,
        });
        if (response && response.status === 200) {
          setRefreshToken(response.data);
          localStorage.setItem("userId", response.data.userid);
          changeUser({ userid: response.data.userid });
        } else {
          setError(true);
        }
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 400)
        ) {
          let localStorageRefreshToken = localStorage.getItem("refreshToken");
          if (localStorageRefreshToken) {
            let result = axios.post(
              "/rgnserver/api/userauthservice/accessTokenByRefershToken",
              { refreshToken: localStorageRefreshToken }
            );
            if (result && result.status === 200) {
              setRefreshToken(result.data);
            } else {
              setError(true);
            }
          } else {
            setError(true);
          }
        } else {
          setError(true);
        }
      }
    };
    getAccessToken();
  }, []);

  return (
    <Box>
      {isError && (
        <Box sx={styles.textStyle}>
          <Typography>Unable to login.</Typography>
        </Box>
      )}
      {loadingCompleted && <Redirect to={renderto} />}
      {!isError && !loadingCompleted && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
export default LoginPage;
