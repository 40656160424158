import React, { useState } from "react";
import { useTheme, Typography, Box } from "@mui/material";
import GridContainer from "../../components/grid/GridContainer.jsx";
import GridItem from "../../components/grid/GridItem.jsx";
import ReSubmitBVIcon from "../../assets/images/reSubmitBV.svg";
import AddNewBVIcon from "../../assets/images/addnewBV.svg";
import SuccessDialog from "../../components/SuccessDialog";
import { useHistory, useRouteMatch } from "react-router-dom";
import { insuranceCoverageDetailsStyle } from "../../components/customstyles/DashboardStyle";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const useStyles = () => {
  const theme = useTheme();
  return insuranceCoverageDetailsStyle(theme);
};

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(97, 97, 97, 0.9)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(97, 97, 97, 0.9)",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "14px",
  },
});

function PatientDetails(props) {
  const styles = useStyles();
  const { patientName, patientEmrId, showResubmit = false } = props;
  let [isEditMedBV, setEditMedBV] = useState(false);
  let [isCreateMedBV, setCreateMedBV] = useState(false);
  let history = useHistory();
  const match = useRouteMatch();
  const handleInitiateMedBV = () => {
    history.push({
      pathname: `./${match.params.patientId}/processtransaction/0`,
    });
    setEditMedBV(false);
    setCreateMedBV(false);
  };
  const handleReInitiateMedBV = () => {
    history.push({
      pathname: `./${match.params.patientId}/processtransaction/1`,
    });
    setEditMedBV(false);
    setCreateMedBV(false);
  };

  const handleEditMedBV = () => {
    setEditMedBV(true);
  };
  const handleCreateNewMedBV = () => {
    setCreateMedBV(true);
  };
  const dismissSuccessDialog = () => {
    setEditMedBV(false);
    setCreateMedBV(false);
  };
  let successDialogTitle;
  let successDialogBody;
  let successButtons;
  if (isCreateMedBV) {
    successDialogTitle = (
      <Typography sx={styles.dialogHeading}>
        Start New Benefits Verification?
      </Typography>
    );
    successDialogBody = (
      <Typography sx={styles.dialogBodytext}>
        Starting a new Benefits Verification will replace the current Benefits
        Verification for this patient. The new Benefits Verification form will
        pre-populate with patient data from your EHR system.
      </Typography>
    );
    successButtons = [
      <Typography onClick={dismissSuccessDialog} sx={styles.dialogbtnStyle}>
        Cancel
      </Typography>,
      <Typography onClick={handleInitiateMedBV} sx={styles.dialogbtnStyle}>
        Start New Benefits Verification
      </Typography>,
    ];
  }
  if (isEditMedBV) {
    successDialogTitle = (
      <Typography sx={styles.dialogHeading}>
        Edit & Resubmit Benefits Verification?
      </Typography>
    );
    successDialogBody = (
      <Typography sx={styles.dialogBodytext}>
        Resubmitting a Benefits Verification will replace the current Benefits
        Verification for this patient. The new form will pre-populate with data
        from the current Benefits Verification, which you may edit.
      </Typography>
    );
    successButtons = [
      <Typography onClick={dismissSuccessDialog} sx={styles.dialogbtnStyle}>
        Cancel
      </Typography>,
      <Typography onClick={handleReInitiateMedBV} sx={styles.dialogbtnStyle}>
        Edit & Resubmit
      </Typography>,
    ];
  }

  return (
    <>
      <GridContainer sx={{ ...styles.tableStyle, ...styles.gridCAlign }}>
        <GridItem
          lg={10}
          md={10}
          sm={10}
          xs={10}
          sx={{ ...styles.gridAlignCenter, ...styles.displayFlex }}
        >
          <Typography sx={styles.headingStyle}>{patientName}</Typography>
        </GridItem>
        <GridItem
          lg={2}
          md={2}
          sm={2}
          xs={2}
          sx={{ ...styles.gridAlignCenter, ...styles.displayFlex }}
        >
          {showResubmit && (
            <>
              <BootstrapTooltip
                title="Edit & Resubmit BV"
                placement="bottom-start"
              >
                <Box sx={styles.addResumeButton}>
                  <img
                    src={ReSubmitBVIcon}
                    onClick={handleEditMedBV}
                    style={styles.btnStyes}
                    alt="re-submit bv"
                  />
                </Box>
              </BootstrapTooltip>
              <BootstrapTooltip title="Start New BV" placement="bottom-start">
                <Box sx={styles.addResumeButton}>
                  <img
                    src={AddNewBVIcon}
                    onClick={handleCreateNewMedBV}
                    style={styles.btnStyes}
                    alt="add new bv"
                  />
                </Box>
              </BootstrapTooltip>
            </>
          )}
        </GridItem>
        <GridItem lg={12} md={12} sm={12} xs={12} sx={styles.paddingBottom}>
          <Typography sx={styles.emrStyle}>{patientEmrId}</Typography>
        </GridItem>
      </GridContainer>

      {(isEditMedBV || isCreateMedBV) && (
        <SuccessDialog
          HeaderText={successDialogTitle}
          successButton={successButtons}
          BodyText={successDialogBody}
          dismiss={""}
        />
      )}
    </>
  );
}
export default PatientDetails;
