import React from "react";
import { MenuItem as MI, Typography } from "@mui/material";
const MenuItem = (props) => {
  const { children, ...other } = props;
  return (
    <MI {...other}>
      <Typography
        type="body3"
        sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}
      >
        {" "}
        {children}{" "}
      </Typography>
    </MI>
  );
};
export default MenuItem;
