import React from "react";
import { useTheme, Card, Typography, Link, Box } from "@mui/material";
import lotImage from "../../assets/images/lot.svg";
import userGuideImage from "../../assets/images/userguide.svg";
import backImage from "../../assets/images/arrow-back.svg";
import snImage from "../../assets/images/sn.svg";
import manufacturerImage from "../../assets/images/manufacturer.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Helmet } from "react-helmet";
import { Stack } from "@mui/material";
const useStyles = () => {
  const theme = useTheme();
  return {
    linkStyle: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "17.5px",
      letterSpacing: "0.25px",
      color: theme.palette.text.linkColor,
    },
    backBtn: {
      cursor: "pointer",
      width: "65px",
      margin: "0 20px 30px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    backBtnText: {
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "1px",
      color: theme.textColour.primaryColor,
      fontWeight: 600,
    },
    container: {
      margin: "40px auto 0",
      maxWidth: { xs: "auto", sm: "900px" },
      width: { xs: "100%" },
    },
    aboutCard: {
      padding: "20px 16px",
      margin: "0 20px",
      borderRadius: "12px",
    },
    infoIcon: {
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.background.primary,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "30px",
      width: "30px",
      padding: "7.5px",
      borderRadius: "50%",
    },
    aboutCardTitle: {
      color: theme.palette.text.textSecondaryColor,
      fontWeight: 600,
      fontSize: "18px",
    },
    subTitleText: {
      fontSize: "14px",
      color: theme.palette.text.textSixthColor,
      paddingBottom: "8px",
      fontWeight: 600,
      lineHeight: "17.5px",
      letterSpacing: "0.25px",
    },
    normalText: {
      color: theme.palette.text.textFifthColor,
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "17.5px",
      letterSpacing: "0.25px",
    },
    userGuideIcon: {
      height: "40px",
      aspectRatio: 1,
    },
  };
};
function AboutPage() {
  const styles = useStyles();
  const userId = localStorage.getItem("userId");
  return (
    <>
      <Helmet>
        <title>About</title>
      </Helmet>
      <Box sx={styles.container}>
        <Box
          sx={styles.backBtn}
          onClick={() => {
            window.history.back();
          }}
        >
          <img src={backImage} alt="back" height="20px" />
          <Typography sx={styles.backBtnText}>Back</Typography>
        </Box>
        <Card sx={styles.aboutCard}>
          <Stack spacing={3}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box sx={styles.infoIcon}>
                <InfoOutlinedIcon sx={{ height: "18px", width: "18px" }} />
              </Box>
              <Typography sx={styles.aboutCardTitle}>
                About EYLEA-NOW
              </Typography>
            </Stack>
            <Stack
              justifyContent="space-between"
              direction={{ xs: "column", sm: "row" }}
              padding="16px"
              spacing={{ xs: 2 }}
            >
              <Box>
                <img src={lotImage} alt="LOT" />
                <Typography sx={styles.subTitleText}>Version Number</Typography>
                <Typography sx={styles.normalText}>
                  {process.env.REACT_APP_VERSION ?? "XXXX"}
                </Typography>
              </Box>
              <Box>
                <img src={snImage} alt="LOT" />
                <Typography sx={styles.subTitleText}>Serial Number</Typography>
                <Typography sx={styles.normalText}>
                  {process.env.REACT_APP_VERSION && userId
                    ? `${process.env.REACT_APP_VERSION}.${userId}`
                    : "XXXX"}
                </Typography>
              </Box>
              <Stack spacing={3}>
                <Box>
                  <img src={manufacturerImage} alt="LOT" />
                  <Typography sx={styles.subTitleText}>Manufacturer</Typography>
                  <Typography sx={styles.normalText}>
                    Amalgam Rx, Inc
                    <br />
                    1007 N. Orange St.
                    <br />
                    Suite 400
                    <br />
                    Wilmington, DE 19801
                    <br />
                    United States
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={styles.subTitleText}>
                    Date of Manufacture:
                  </Typography>
                  <Typography sx={styles.normalText}>
                    {process.env.REACT_APP_DATE ?? "XXX-XX-XX"}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
            <Box>
              <Typography sx={styles.subTitleText}>Intended Use</Typography>
              <Typography sx={styles.normalText}>
                EYLEA-NOW is intended for use by retinal specialty office staff,
                clinical scribes, and ophthalmic technologists to view
                preliminary EYLEA HD (8 mg) or EYLEA (2 mg) payer coverage
                information for a patient using the plan name that is in
                practice EHR or PM system. It can be used to help facilitate
                automatic enrollment into E4U using patient information in
                practice EHR/PM system.
              </Typography>
            </Box>
            <Box>
              <Typography sx={styles.subTitleText}>
                Supported Browsers
              </Typography>
              <Typography sx={styles.normalText}>
                EYLEA-NOW is supported with the latest version(s) of Chrome,
                Safari, Edge, and Firefox.
              </Typography>
            </Box>
            <Box>
              <img
                src={userGuideImage}
                alt="user guide"
                style={styles.userGuideIcon}
              />
              <Typography>
                <Link
                  target="_blank"
                  underline="none"
                  href={`${process.env.PUBLIC_URL}/EYLEA-NOW User Guide - Rev. A.pdf`}
                  sx={styles.linkStyle}
                >
                  User Guide
                </Link>
              </Typography>
            </Box>
            <Box>
              <Typography sx={styles.subTitleText}>
                Technical Support
              </Typography>
              <Typography>
                <Link
                  target="_blank"
                  sx={styles.linkStyle}
                  underline="none"
                  href="https://mail.google.com/mail/?view=cm&fs=1&to=EYLEA-NOW@amalgamrx.com"
                >
                  EYLEA-NOW@amalgamrx.com
                </Link>
              </Typography>
            </Box>
          </Stack>
        </Card>
      </Box>
    </>
  );
}
export default AboutPage;
