import React from "react";
import { TextField } from "@mui/material";

export default function ({ children, ...otherProps }) {
  return (
    <>
      <TextField
        color="customInput"
        InputLabelProps={{
          color: "customInput",
        }}
        {...otherProps}
      />
      {children}
    </>
  );
}
