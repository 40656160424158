import React from "react";
import { useTheme, Box } from "@mui/material";
import { getDashboardStyle } from "../../components/customstyles/DashboardStyle";
import ErrorOutlineIcon from "../../assets/images/error-outline.svg";
const useStyles = () => {
  const theme = useTheme();
  return getDashboardStyle(theme);
};

export const INFO_ICON_FOR_STATUSES = [
  "BV - Missing Information",
  "Benefits Undetermined",
  "BV cannot be completed",
  "Plan Inactive",
];

function BVStatusPill({ status }) {
  const styles = useStyles();
  return (
    <Box sx={styles.BVPill}>
      {INFO_ICON_FOR_STATUSES.includes(status) && (
        <img
          alt="bv warning"
          src={ErrorOutlineIcon}
          style={styles.BVPillIcon}
        />
      )}
      <Box sx={styles.BVStatusText}>
        {status?.toUpperCase() ?? "NO BV STATUS FOUND"}
      </Box>
    </Box>
  );
}

export default BVStatusPill;
