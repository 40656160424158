import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import {
  useTheme,
  CircularProgress,
  Typography,
  Box,
  Divider,
  List,
  ListItem,
} from "@mui/material";
import GridContainer from "../../components/grid/GridContainer.jsx";
import GridItem from "../../components/grid/GridItem.jsx";
import { searchInsuranceStyle } from "../../components/customstyles/DashboardStyle";
import Button from "../../components/customcomponents/CustomButton";
import ResultNotFoundUI from "../../components/ResultNotFoundUI";
import { Formik, Form, connect } from "formik";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { string, object } from "yup";
import ReactPaginate from "react-paginate";
import backImage from "../../assets/images/arrow-back.svg";
import SearchInsuranceUI from "./SearchInsurnaceUI";
import { AuthContext } from "../../context/AuthContext";
import "./ReactPaginate.css";
const useStyles = () => {
  const theme = useTheme();
  return searchInsuranceStyle(theme);
};

function FuzzyInsuranceSearch() {
  const styles = useStyles();
  const itemsPerPage = 10;
  const [insuranceListData, setInsuranceListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [emptyResult, setEmptyResult] = useState(false);
  const [showPagination, setShowPagination] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const { makeAuthenticatedApiCall } = useContext(AuthContext);
  const yupSchema = object().shape({
    insuranceName: string().trim().required("This field is required."),
  });
  const handleSelect = async (value) => {
    let updateInsuranceObj = {
      patientId: match.params.patientId,
      matchedSiteInsuranceName: value,
    };
    await makeAuthenticatedApiCall(
      "put",
      `/rgnserver/api/insuranceplan/updatePatientMatchedSiteInsurance`,
      updateInsuranceObj
    );
    history.push({
      pathname: `../insurancecoverage/${match.params.patientId}`,
      state: { clickedSelect: true },
    });
  };

  useEffect(() => {
    let insuranceName = location.state && location.state.insuranceName;
    const getInsuranceList = async () => {
      if (insuranceName) {
        setLoading(true);
        let response = await makeAuthenticatedApiCall(
          "get",
          `/rgnserver/api/insuranceplan/getinsurancelist/${insuranceName}`,
          {}
        );
        if (response?.status === 200 && response.data.length > 0) {
          if (response.data.length > itemsPerPage) {
            setShowPagination(true);
          }
          setResult(true);
          setEmptyResult(false);
        } else {
          setEmptyResult(true);
        }
        setLoading(false);
        setInsuranceListData(response.data);
      }
    };
    getInsuranceList();
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    let response = await makeAuthenticatedApiCall(
      "get",
      `/rgnserver/api/insuranceplan/getinsurancelist/${values.insuranceName}`,
      {}
    );
    if (response?.status === 200 && response.data.length > 0) {
      if (response.data.length > itemsPerPage) {
        setShowPagination(true);
      } else {
        setShowPagination(false);
      }
      setResult(true);
      setInsuranceListData(response.data);
      setEmptyResult(false);
    } else {
      setShowPagination(false);
      setInsuranceListData([]);
      setEmptyResult(true);
    }
    setLoading(false);
  };
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = insuranceListData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(insuranceListData.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % insuranceListData.length;
    setItemOffset(newOffset);
  };
  return (
    <>
      <Box sx={styles.tableStyle}>
        <GridContainer>
          <Box
            sx={styles.backBtn}
            onClick={() => {
              window.history.back();
            }}
          >
            <img src={backImage} alt="back" height="20px" />
            <Typography sx={styles.backBtnText}>Back</Typography>
          </Box>
          <GridItem lg={12} md={12} sm={12} xs={12} sx={styles.gridAlign}>
            <span style={styles.headingStyle}>Select an Insurance Plan</span>
            <Typography sx={styles.subHeadingStyle}>
              Please select an insurance plan from the list below to check
              preliminary coverage.
            </Typography>
            <br />
            <Formik
              initialValues={{ insuranceName: "" }}
              onSubmit={handleSubmit}
              validationSchema={yupSchema}
            >
              {(props) => (
                <Form>
                  <GridContainer sx={styles.inlineStyle}>
                    <GridItem
                      lg={10}
                      md={10}
                      sm={12}
                      xs={12}
                      sx={styles.gridAlign}
                    >
                      <SearchInsuranceUI />
                    </GridItem>
                    <GridItem
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      sx={styles.gridAlign}
                    >
                      <Button
                        primary
                        disabled={props.values.insuranceName.trim() === ""}
                        type="submit"
                        wrapperSx={styles.buttonStyle}
                        sx={styles.searchButtonStyle}
                      >
                        Search
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Form>
              )}
            </Formik>
          </GridItem>
        </GridContainer>
        {loading && (
          <Box sx={styles.loadingInsuranceStyle}>
            <Box sx={{ marginBottom: "20px" }}>
              <CircularProgress sx={{ color: "#00446B" }} />
            </Box>
            <Typography sx={styles.loadinText}>Loading...</Typography>
          </Box>
        )}
        {result && (
          <List>
            {currentItems.map((item, index) => (
              <div key={index}>
                <ListItem>
                  <GridContainer sx={styles.gridCAlign}>
                    <GridItem
                      lg={10}
                      md={10}
                      sm={10}
                      xs={8}
                      sx={styles.gridAlign}
                    >
                      <Typography sx={styles.subHeadingStyle}>
                        {item}
                      </Typography>
                    </GridItem>
                    <GridItem
                      lg={2}
                      md={2}
                      sm={2}
                      xs={4}
                      sx={{ textAlign: "right" }}
                    >
                      <Button
                        sx={styles.selectButtonStyle}
                        onClick={() => {
                          handleSelect(item);
                        }}
                      >
                        SELECT
                      </Button>
                    </GridItem>
                  </GridContainer>
                </ListItem>
                {index !== currentItems.length - 1 && <Divider />}
              </div>
            ))}
          </List>
        )}
        {showPagination && (
          <GridContainer sx={styles.gridCAlign}>
            <GridItem
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={styles.paginationStyle}
            >
              <ReactPaginate
                activeClassName="itemStyle activeItem"
                breakClassName="itemStyle"
                containerClassName="paginationContainer"
                disabledClassName="disabledItemStyle"
                nextClassName="itemStyle nextStyle"
                nextLabel={<ArrowForwardIosIcon sx={{ fontSize: 18 }} />}
                pageRangeDisplayed={4}
                marginPagesDisplayed={2}
                breakLabel="..."
                onPageChange={handlePageClick}
                pageCount={pageCount}
                previousLabel={<ArrowBackIosIcon sx={{ fontSize: 18 }} />}
                renderOnZeroPageCount={null}
                pageClassName="itemStyle paginationPage"
                previousClassName="itemStyle previousStyle"
              />
            </GridItem>
          </GridContainer>
        )}
        {emptyResult && <ResultNotFoundUI errorMessage={false} />}
      </Box>
    </>
  );
}
export default connect(FuzzyInsuranceSearch);
