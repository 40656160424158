import React, { useEffect } from "react";
import { useTheme } from "@mui/material";
import FormikTextField from "../../components/formikcomponent/TextField";
import { Field, connect } from "formik";
import { searchInsuranceStyle } from "../../components/customstyles/DashboardStyle";
import { useLocation } from "react-router-dom";
const useStyles = () => {
  const theme = useTheme();
  return searchInsuranceStyle(theme);
};

function SearchInsurnaceUI(props) {
  const styles = useStyles();
  const location = useLocation();
  useEffect(() => {
    let insuranceName = location.state && location.state.insuranceName;
    if (insuranceName) {
      const setInsurnaceName = async () => {
        props.formik.setFieldValue("insuranceName", insuranceName, false);
      };
      setInsurnaceName();
    }
  }, []);
  return (
    <>
      <Field
        name="insuranceName"
        style={styles.inputItem}
        variant="outlined"
        label="Insurance Name"
        component={FormikTextField}
        InputProps={{
          style: {
            backgroundColor: "#fff",
            minHeight: "47px",
            fontSize: "14px",
          },
          // classes: {
          //   input: "searchInput",
          // },
        }}
      />
    </>
  );
}
export default connect(SearchInsurnaceUI);
