import React, { useContext, useEffect, useState } from "react";
import {
  CircularProgress,
  Typography,
  useTheme,
  Box,
  Button,
  Stack,
} from "@mui/material";
import GridContainer from "../../components/grid/GridContainer.jsx";
import GridItem from "../../components/grid/GridItem.jsx";
import { Helmet } from "react-helmet";
import MuiDataGrid from "../../components/MuiDataGrid.jsx";
import { gridStringOrNumberComparator } from "@mui/x-data-grid";
import Search from "../../components/customcomponents/Search";
import BVStatusPill from "./BVStatusPill";
import _ from "lodash";
import { getDashboardStyle } from "../../components/customstyles/DashboardStyle";
import { AppointmentContext } from "../../context/AppointmentContext";
import { AuthContext } from "../../context/AuthContext";
import { appointmentDateTimeFormatter } from "../../components/utilFunctions.js";
import { useHistory } from "react-router-dom";
import { drugNameMap } from "../../utils/lookup.js";

const useStyles = () => {
  const theme = useTheme();
  return getDashboardStyle(theme);
};

const sortByAppointment = (keyToSearch) => (a, b, order) => {
  const aVal = _.get(a, keyToSearch);
  const bVal = _.get(b, keyToSearch);
  // if either date is invalid, put it at the end
  if (!aVal) {
    return order === "desc" ? -1 : 1;
  } else if (!bVal) {
    return order === "desc" ? 1 : -1;
  }
  const aDate = new Date(aVal);
  const bDate = new Date(bVal);
  return aDate - bDate;
};

const appointmentColumnSortFn = sortByAppointment("date");
const DefaultBVStatus = "BV Not Started";

const NotApplicableUI = ({ variant = "small" }) => {
  const styles = useStyles();
  return (
    <Typography
      sx={{
        ...styles.noInsuranceText,
        paddingY: variant === "big" ? "20px" : "10px",
      }}
    >
      N/A
    </Typography>
  );
};

function PatientsDashboard() {
  const styles = useStyles();

  const [patientsData, setPatientsData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState([true]);
  const { appointments, setAppointments } = useContext(AppointmentContext);
  const { makeAuthenticatedApiCall } = useContext(AuthContext);
  const history = useHistory();

  // table column defenition
  const commonColumnOptions = {
    resizable: false,
    aggregable: false,
    gropable: false,
    hideable: false,
    pinnable: false,
  };

  let columns = [
    {
      ...commonColumnOptions,
      field: "appointment",
      flex: 1,
      minWidth: 150,
      sortComparator: (a, b, meta) => {
        const order = meta.api.getSortModel()?.[0]?.sort;
        return appointmentColumnSortFn(a, b, order);
      },
      renderHeader: () => (
        <Typography sx={styles.tableLabelColor}>Appointment</Typography>
      ),
      renderCell: (params) => (
        <div style={{ height: "100%", width: "100%", paddingTop: "10px" }}>
          {params.value?.date ? (
            <div style={{ padding: "8px 0" }}>
              <Typography sx={styles.TableBodySubText}>
                {appointmentDateTimeFormatter(params.value?.date)}
              </Typography>
              <Typography
                sx={{
                  ...styles.patientEmrText,
                  paddingTop: "6px",
                  whiteSpace: "nowrap",
                }}
              >
                {params.value?.location}
              </Typography>
            </div>
          ) : (
            <NotApplicableUI variant="big" />
          )}
        </div>
      ),
    },
    {
      ...commonColumnOptions,
      field: "patient",
      flex: 1,
      minWidth: 120,
      sortComparator: (a, b, meta1, meta2) =>
        gridStringOrNumberComparator(
          a.patientName,
          b.patientName,
          meta1,
          meta2
        ),
      renderHeader: () => (
        <Typography sx={styles.tableLabelColor}>Patient</Typography>
      ),
      renderCell: (params) => (
        <div style={{ height: "100%", width: "100%", paddingTop: "10px" }}>
          <div style={{ padding: "8px 0" }}>
            <Typography sx={styles.TableBodySubText}>
              {params.value.patientName}
            </Typography>
            <Typography sx={{ ...styles.patientEmrText, paddingTop: "6px" }}>
              {params.value.patientEmrId}
            </Typography>
          </div>
        </div>
      ),
    },
    {
      ...commonColumnOptions,
      field: "doctor",
      flex: 1,
      minWidth: 120,
      renderHeader: () => (
        <Typography sx={styles.tableLabelColor}>Doctor</Typography>
      ),
      renderCell: (params) => (
        <div style={{ height: "100%", width: "100%", padding: "20px 0" }}>
          <Typography
            sx={{
              ...styles.TableBodyText,
              padding: "8px 0",
            }}
          >
            {params.value}
          </Typography>
        </div>
      ),
    },
    {
      ...commonColumnOptions,
      field: "insuranceName",
      flex: 2.2,
      minWidth: 180,
      renderHeader: () => (
        <Typography sx={styles.tableLabelColor}>Plan Name</Typography>
      ),
      renderCell: (params) => (
        <div a style={{ height: "100%", width: "100%", padding: "20px 0" }}>
          {params.value !== "PLAN NOT CONFIRMED" ? (
            <Typography
              sx={{
                ...styles.TableBodyText,
                padding: "8px 0",
              }}
            >
              {params.value}
            </Typography>
          ) : (
            <Typography sx={{ ...styles.noInsuranceText, padding: "8px 0" }}>
              PLAN NOT CONFIRMED
            </Typography>
          )}
        </div>
      ),
    },
    {
      ...commonColumnOptions,
      field: "diagnosisCode",
      flex: 0.7,
      minWidth: 120,
      sortComparator: (a, b, meta) => {
        const order = meta.api.getSortModel()?.[0]?.sort;
        // sort based on first not null diagnosisCodes
        const diagnosisCode1 = a?.find((code) => !!code);
        const diagnosisCode2 = b?.find((code) => !!code);
        return gridStringOrNumberComparator(
          diagnosisCode1,
          diagnosisCode2,
          order
        );
      },
      renderHeader: () => (
        <Typography sx={styles.tableLabelColor}>diagnosis</Typography>
      ),
      renderCell: ({ value }) => (
        <Stack
          height={"100%"}
          direction="column"
          spacing={"20px"}
          paddingY={"20px"}
        >
          {Array.isArray(value) && value.length > 0 ? (
            value.map((diagCode, i) =>
              diagCode ? (
                <Typography key={i} sx={styles.TableBodyText} paddingY={"8px"}>
                  {diagCode}
                </Typography>
              ) : (
                <NotApplicableUI key={i} />
              )
            )
          ) : (
            <NotApplicableUI />
          )}
        </Stack>
      ),
    },
    {
      ...commonColumnOptions,
      field: "drugName",
      flex: 1,
      minWidth: 130,
      sortComparator: (a, b) => {
        if (a.length === 0 && b.length === 0) return 0;
        // sort based on drugName array length, if both length same, sort alphabetically
        if (a.length === b.length) {
          return a[0].localeCompare(b[0]);
        } else {
          return a.length - b.length;
        }
      },
      renderHeader: () => (
        <Typography sx={styles.tableLabelColor}>medication</Typography>
      ),
      renderCell: ({ value }) => (
        <Stack
          height={"100%"}
          direction="column"
          spacing={"20px"}
          paddingY={"20px"}
        >
          {Array.isArray(value) && value.length > 0 ? (
            value.map((drugName, i) =>
              drugName ? (
                <Typography
                  key={i}
                  sx={{ ...styles.TableBodyText, whiteSpace: "nowrap" }}
                  paddingY={"8px"}
                >
                  {drugName}
                </Typography>
              ) : (
                <NotApplicableUI key={i} />
              )
            )
          ) : (
            <NotApplicableUI />
          )}
        </Stack>
      ),
    },
    {
      ...commonColumnOptions,
      field: "medBVStatus",
      flex: 1.5,
      minWidth: 230,
      sortComparator: (a, b, meta) => {
        const order = meta.api.getSortModel()?.[0]?.sort;
        // sort based on first medBVStatus
        const status1 = a?.[0] ?? DefaultBVStatus;
        const status2 = b?.[0] ?? DefaultBVStatus;
        return gridStringOrNumberComparator(status1, status2, order);
      },
      renderHeader: () => (
        <Typography sx={styles.tableLabelColor}>status</Typography>
      ),
      renderCell: ({ value }) => (
        <Stack height={"100%"} direction="column" spacing={3} paddingY={"24px"}>
          {Array.isArray(value) && value.length > 0 ? (
            value.map((status, i) => (
              <BVStatusPill key={i} status={status ?? DefaultBVStatus} />
            ))
          ) : (
            <BVStatusPill status={DefaultBVStatus} />
          )}
        </Stack>
      ),
    },
  ];

  const handleRowClick = ({ row }) => {
    history.push(
      `./patients/insurancecoverage/${row?.patient?.patientId}?addBy=sso`
    );
  };

  const changePatientList = (selecteddPatientList) => {
    if (selecteddPatientList !== undefined) {
      if (selecteddPatientList.length > 0) {
        setTableData(selecteddPatientList);
      } else if (selecteddPatientList.length === 0) {
        setTableData([]);
      } else {
        setTableData(null);
      }
    }
  };

  useEffect(() => {
    const getPatientsList = async () => {
      let tableData = [];
      if (patientsData.length === 0) {
        let response = await makeAuthenticatedApiCall(
          "get",
          `/rgnserver/api/insuranceplan/getpatientslist`,
          {}
        );
        if (response?.status === 200 && response?.data?.length > 0) {
          response.data.forEach((item, index) => {
            // sort drug details
            item?.drugDetails?.sort((a, b) =>
              b.drugName?.localeCompare(a.drugName)
            );
            tableData.push({
              id: `${item.patientId}-${index}`,
              patient: {
                patientId: item?.patientId,
                patientName: item?.patientName,
                patientEmrId: item?.patientEmrId,
              },
              insuranceName: item?.insuranceName,
              doctor: item?.providerName,
              appointment: {
                date: item?.appointmentDate,
                location: item?.appointmentLocation,
              },
              diagnosisCode: item?.drugDetails?.map(
                (drug) => drug.diagnosisCode
              ),
              drugName: item?.drugDetails?.map(
                (drug) => drugNameMap[drug.drugName]
              ),
              medBVStatus: item?.drugDetails?.map((drug) => drug.medBVStatus),
            });
          });
        }
      } else {
        tableData = patientsData;
      }
      let appointmentDates = [];
      if (appointments.length === 0) {
        let response = await makeAuthenticatedApiCall(
          "get",
          `/rgnserver/api/insuranceplan/updatepatientsappointment`,
          {}
        );
        if (Array.isArray(response.data) && response.data.length > 0) {
          appointmentDates = response.data;
          setAppointments(response.data);
        }
      } else {
        appointmentDates = appointments;
      }
      tableData = tableData.map((row) => {
        const appointment = appointmentDates.find(
          (a) => a.patientId === row.patient.patientId
        );
        return {
          ...row,
          appointment: {
            ...row.appointment,
            date:
              appointment && new Date(appointment.appointmentDate) > new Date()
                ? appointment.appointmentDate
                : null,
          },
        };
      });
      // initially sort based on appointment dates ascending
      tableData.sort(sortByAppointment("appointment.date"));
      setLoading(false);
      setPatientsData(tableData);
    };
    if (patientsData.length === 0) {
      getPatientsList();
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Box sx={styles.tableStyle}>
      <Helmet>
        <title>Patients</title>
      </Helmet>
      <GridContainer>
        <GridItem lg={4} md={4} sm={12} xs={12} sx={styles.gridAlign}>
          <p style={styles.patientHeading}>Patients</p>
          <Search
            pathToSearch={"patient.patientName"}
            searchPlaceholder={"Search Patients"}
            list={patientsData}
            filterList={changePatientList}
          />
        </GridItem>
        <GridItem
          lg={8}
          md={8}
          sm={12}
          xs={12}
          sx={{
            ...styles.gridAlign,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Button
            variant="contained"
            sx={styles.addPatientButton}
            onClick={() => {
              history.push("/patients/add");
            }}
          >
            Add A Patient
          </Button>
        </GridItem>
      </GridContainer>
      {loading && tableData ? (
        <Box sx={styles.loadingLeftStyle}>
          <Box sx={{ marginBottom: "20px" }}>
            <CircularProgress />
          </Box>
          <Typography sx={styles.loadinText}>
            Loading patients details...
          </Typography>
        </Box>
      ) : (
        <GridContainer>
          <GridItem md={12}>
            <MuiDataGrid
              rows={tableData}
              columns={columns}
              onRowClick={handleRowClick}
              getRowHeight={() => "auto"}
              slotProps={{
                row: {
                  style: { cursor: "pointer" },
                },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "appointment", sort: "asc" }],
                },
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              pageSizeOptions={[10, 20, 50]}
              autoPageSize={false}
            />
          </GridItem>
        </GridContainer>
      )}
    </Box>
  );
}
export default PatientsDashboard;
