import React, { useContext, useEffect, useState } from "react";
import { useTheme, CircularProgress, Typography, Box } from "@mui/material";
import { Helmet } from "react-helmet";
import ErrorDialog from "../../components/ErrorDialog";
import Button from "../../components/customcomponents/CustomButton";
import InsuranceCoverageGuideUI from "./InsuranceCoverageGuideUI";
import BenefitsVerificationUI from "./BenefitsVerificationUI";
import ErrorDetails from "../CareMetxTransaction/ErrorDetails";
import PatientDetails from "./PatientDetails";
import IframeResizer from "iframe-resizer-react";
import IframeResizerJs from "../../assets/IframeResizerJs";
import { insuranceCoverageDetailsStyle } from "../../components/customstyles/DashboardStyle";
import { AuthContext } from "../../context/AuthContext";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import ErrorOutlineIcon from "../../assets/images/error-outline.svg";
import { INFO_ICON_FOR_STATUSES } from "../patients/BVStatusPill";
import GridContainer from "../../components/grid/GridContainer";
import { drugNameMap } from "../../utils/lookup";
const useStyles = (theme) => {
  return insuranceCoverageDetailsStyle(theme);
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const BE_NOT_STARTED_STATUS = "BV not Started";
function InsuranceCoverageDetails() {
  const theme = useTheme();
  const styles = useStyles(theme);
  const { makeAuthenticatedApiCall } = useContext(AuthContext);
  const match = useRouteMatch();
  const search = useQuery();
  const history = useHistory();
  const [insuranceResponse, setInsuranceResponse] = useState(null);
  const [matchedSiteInsuranceName, setMatchedSiteInsuranceName] =
    useState(null);
  const [patientName, setPatientName] = useState(null);
  const [patientEmrId, setPatientEmrId] = useState(null);
  const [medBVHtmlContent, setMedBVHtmlContent] = useState("");
  const [loading, setLoading] = useState([true]);
  const [noPatientFound, setNoPatientFound] = useState(false);
  const [errorDetails, setErrorDetails] = useState(null);
  const [coverageCompleted, setCoverageDetails] = useState(false);
  const [dataToShowMedBV, setDataToShowMedBV] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [noBenefitVerification, setBenefitVerification] = useState(false);

  const handleChange = (event, index) => {
    setErrorDetails(medBVHtmlContent[index].error);
    setDataToShowMedBV(medBVHtmlContent[index]);
    setSelectedIndex(index);
  };

  let handleCoverageDatailsAPI = async (matchedSiteInsuranceName) => {
    const response = await makeAuthenticatedApiCall(
      "get",
      `/rgnserver/api/insuranceplan/getinsurancecoveragedetails/${encodeURIComponent(
        matchedSiteInsuranceName
      )}`,
      {}
    );
    setInsuranceResponse(response);
    setLoading(false);
    setCoverageDetails(true);
  };

  useEffect(() => {
    const addBy = search.get("addBy");
    // remove addBy from url
    history.replace(match.url);
    const getInsuranceDetails = async () => {
      if (match?.params.patientId) {
        const apiParams = addBy ? `?addedThrough=${addBy}` : "";
        let response = await makeAuthenticatedApiCall(
          "get",
          `/rgnserver/api/insuranceplan/getpatientdetails/${match.params.patientId}${apiParams}`,
          {}
        );

        if (response?.status === 200) {
          let patientData = response.data;
          const IframeResizerScript = `<script type="text/javascript">${IframeResizerJs.data}</script>`;
          // initialise medBVDetails
          let medBVDetails = Object.values(drugNameMap).map((drugName) => ({
            drugName,
            medBVStatus: BE_NOT_STARTED_STATUS,
            error: undefined,
            isDisabled: true,
            medBVHTML: "",
          }));
          let statusCount = 0;
          // benefits verification section
          if (
            patientData?.medBVStatuses?.some(
              (item) => item?.medBVStatus !== BE_NOT_STARTED_STATUS
            )
          ) {
            setBenefitVerification(true);
          }
          // override medBVDetails from response
          medBVDetails = medBVDetails.map((item, index) => {
            const drugFromResponse = patientData?.medBVStatuses?.find(
              (drug) => drugNameMap?.[drug.drugName] === item.drugName
            );
            if (drugFromResponse === undefined) {
              return item;
            }

            const newMedBVObj = {
              drugName: drugNameMap[drugFromResponse?.drugName],
              medBVStatus: drugFromResponse?.medBVStatus,
              error: drugFromResponse?.error?.errorData || null,
              isDisabled:
                drugFromResponse?.medBVStatus === BE_NOT_STARTED_STATUS,
              medBVHTML:
                drugFromResponse?.error === null &&
                drugFromResponse?.medBVHTML?.replace(
                  "</div>\r\n</body>",
                  `${IframeResizerScript}</div>\r\n</body>`
                ),
            };
            if (
              drugFromResponse.medBVStatus !== BE_NOT_STARTED_STATUS &&
              statusCount === 0
            ) {
              statusCount = statusCount + 1;
              setDataToShowMedBV(newMedBVObj);
              setErrorDetails(newMedBVObj.error);
              setSelectedIndex(index);
            }
            return newMedBVObj;
          });
          setMedBVHtmlContent(medBVDetails);
          setLoading(false);
          setPatientName(patientData.patientName);
          setPatientEmrId(patientData.emrPatientId);
          setMatchedSiteInsuranceName(patientData.matchedSiteInsuranceName);
          handleCoverageDatailsAPI(patientData.matchedSiteInsuranceName);
        } else {
          setNoPatientFound(true);
        }
      }
    };
    getInsuranceDetails();
  }, []);

  const dismissDialog = () => {
    history.push(`../../patients`);
  };
  let HeaderText =
    "We were unable to find the patient. Please check the Patients dashboard.";
  return (
    <>
      <Helmet>
        <title>Insurance Coverage Details</title>
      </Helmet>
      {loading ? (
        <>
          <Box sx={styles.loadingCoverageStyle}>
            <Box sx={{ marginBottom: "20px" }}>
              <CircularProgress />
            </Box>
            <Typography sx={styles.loadinText}>
              Loading plan details...
            </Typography>
          </Box>
          {noPatientFound && (
            <ErrorDialog HeaderText={HeaderText} dismiss={""}>
              <Button primary sx={styles.loginButton} onClick={dismissDialog}>
                Ok
              </Button>
            </ErrorDialog>
          )}
        </>
      ) : (
        <Box
          sx={{
            minWidth: medBVHtmlContent ? "1040px" : undefined,
            maxWidth: medBVHtmlContent ? "1150px" : undefined,
            margin: "0 auto",
          }}
        >
          <PatientDetails
            patientEmrId={patientEmrId}
            patientName={patientName}
            showResubmit={noBenefitVerification}
          />

          {noBenefitVerification ? (
            <>
              <GridContainer sx={styles.styleFlex}>
                {medBVHtmlContent.map((item, index) => {
                  return (
                    <div key={index}>
                      <Button
                        onClick={(item) => handleChange(item, index)}
                        disabled={item.isDisabled}
                        sx={
                          selectedIndex == index && {
                            ...styles.borderBottomStyle,
                            borderRadius: "0",
                          }
                        }
                      >
                        <Typography
                          sx={
                            item.isDisabled
                              ? styles.drugNameDisabledStyle
                              : styles.drugNameStyle
                          }
                        >
                          {item.drugName}
                        </Typography>
                        <Box
                          sx={{ ...styles.medbvStatusStyle, cursor: "pointer" }}
                        >
                          {INFO_ICON_FOR_STATUSES.includes(
                            item.medBVStatus
                          ) && (
                            <img
                              alt="bv warning"
                              src={ErrorOutlineIcon}
                              style={styles.medbvStatusIconStyle}
                            />
                          )}
                          <Typography sx={styles.btnTextStyle}>
                            {item.medBVStatus}
                          </Typography>
                        </Box>
                      </Button>
                    </div>
                  );
                })}
              </GridContainer>
              {dataToShowMedBV.error === null ? (
                <Box sx={{ padding: "0 20px" }}>
                  <IframeResizer
                    checkOrigin={false}
                    log={false}
                    srcDoc={dataToShowMedBV.medBVHTML}
                    style={{
                      width: "100%",
                      minWidth: "100%",
                      minHeight: "400px",
                      border: `2px solid ${theme.custom.iFrameBorder}`,
                      borderRadius: "5px",
                      backgroundColor: "white",
                    }}
                  />
                </Box>
              ) : (
                <ErrorDetails errorDetails={errorDetails} />
              )}
            </>
          ) : (
            <Box sx={styles.tableStyle}>
              <div>
                <BenefitsVerificationUI />
                {coverageCompleted ? (
                  <>
                    <InsuranceCoverageGuideUI
                      patientName={patientName}
                      insuranceResponse={insuranceResponse}
                      matchedSiteInsuranceName={matchedSiteInsuranceName}
                    />
                    <Typography sx={styles.guaranteeText}>
                      <b>THIS DOES NOT GUARANTEE</b> that {patientName} will be
                      covered for Eylea HD (8 mg) or Eylea (2 mg). It is only
                      intended as a guide based on the general insurance policy.
                      To confirm coverage of Eylea HD (8 mg) and Eylea (2 mg)
                      for {patientName}, please run a Benefits Verification. All
                      trademark and brand names are properties of their
                      respective owners.
                    </Typography>
                  </>
                ) : (
                  <Box
                    sx={{
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </div>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
export default InsuranceCoverageDetails;
