import React from "react";
import { DataGrid } from "@mui/x-data-grid";

function MuiDataGrid({
  rows,
  columns,
  sx,
  onRowClick,
  ...additionalTableOptions
}) {
  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu={true}
        disableColumnSelector={true}
        showCellVerticalBorder={false}
        showColumnVerticalBorders={false}
        disableRowSelectionOnClick={true}
        onRowClick={onRowClick}
        sx={{ border: "none", ...sx }}
        autoHeight={true}
        {...additionalTableOptions}
      />
    </div>
  );
}

export default MuiDataGrid;
