import React from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import FuzzyInsuranceSearch from "../PatientDetails/FuzzyInsuranceSearch";
import InsuranceCoverageDetails from "../PatientDetails/InsuranceCoverageDetails";
import CareMetxTransaction from "../CareMetxTransaction/CareMetxTransactionDetails.jsx";
import PatientAdd from "./PatientAdd";
import PatientsDashboard from "./PatientsDashboard";

function Patients() {
  const match = useRouteMatch();
  return (
    <>
      <Helmet>
        <title>Patient</title>
      </Helmet>
      <Switch>
        <Route
          path={`${match.url}/search-insurancelist/:patientId`}
          component={FuzzyInsuranceSearch}
        />
        <Route
          path={`${match.url}/insurancecoverage/:patientId/processtransaction/:useFromEHRFlag`}
          component={CareMetxTransaction}
        />
        <Route
          path={`${match.url}/insurancecoverage/:patientId`}
          component={InsuranceCoverageDetails}
        />
        <Route path={`${match.url}/add`} component={PatientAdd} />
        <Route path={`${match.url}`} component={PatientsDashboard} />
      </Switch>
    </>
  );
}
export default Patients;
