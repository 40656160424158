import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  useTheme,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
} from "@mui/material";
import GridContainer from "../../components/grid/GridContainer.jsx";
import GridItem from "../../components/grid/GridItem.jsx";
import preliminaryImage from "../../assets/images/preliminaryCheck.svg";
import ResultNotFoundUI from "../../components/ResultNotFoundUI";
import { insuranceCoverageDetailsStyle } from "../../components/customstyles/DashboardStyle";
import IndicationsTable from "../../components/IndicationsTable.jsx";
const useStyles = () => {
  const theme = useTheme();
  return insuranceCoverageDetailsStyle(theme);
};

function PreliminaryCoverageGuideUI({
  insuranceResponse,
  matchedSiteInsuranceName,
  patientName,
}) {
  const styles = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const handleChange = () => {
    history.push({
      pathname: `../search-insurancelist/${match.params.patientId}`,
      state: { insuranceName: matchedSiteInsuranceName },
    });
  };
  return (
    <>
      <GridContainer sx={styles.gridCAlign}>
        <Card sx={styles.cardStyle} variant="outlined">
          <CardContent>
            <GridContainer sx={styles.gridCAlign}>
              <GridItem lg={12} md={12} sm={12} xs={12} sx={styles.gridAlign}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={styles.headingImage}>
                    <img
                      src={preliminaryImage}
                      className={styles.imageStyle}
                      alt="preliminary coverage check"
                    />
                  </Box>
                  <Typography sx={styles.investigationText}>
                    Preliminary Coverage Guide
                  </Typography>
                </Box>
              </GridItem>
            </GridContainer>
            {insuranceResponse.status === 200 && insuranceResponse?.data ? (
              <>
                <GridContainer sx={styles.gridCAlign}>
                  <GridItem
                    lg={10}
                    md={10}
                    sm={10}
                    xs={12}
                    sx={styles.gridAlign}
                  >
                    <Typography sx={styles.prelimniryText}>
                      {insuranceResponse.data.siteInsuranceName}
                    </Typography>
                  </GridItem>
                  <GridItem
                    lg={2}
                    md={2}
                    sm={2}
                    xs={12}
                    sx={{ ...styles.gridAlign, ...styles.alignItemRight }}
                  >
                    <Button
                      sx={styles.changeLink}
                      onClick={() => {
                        handleChange();
                      }}
                    >
                      Change
                    </Button>
                  </GridItem>
                </GridContainer>
                <Box sx={{ paddingBottom: "24px" }}>
                  <IndicationsTable
                    insuranceDrugData={insuranceResponse.data?.drugs}
                  />
                </Box>
              </>
            ) : (
              <>
                {insuranceResponse.status === 400 && (
                  <GridContainer
                    sx={{ ...styles.gridCAlign, paddingBottom: "24px" }}
                  >
                    <GridItem lg={10} md={10} sm={10} xs={10}>
                      <ResultNotFoundUI errorMessage={true} />
                    </GridItem>
                    <GridItem
                      lg={2}
                      md={2}
                      sm={2}
                      xs={12}
                      sx={{
                        ...styles.gridAlign,
                        ...styles.alignItemRight,
                      }}
                    >
                      <Button
                        sx={styles.changeLink}
                        onClick={() => {
                          handleChange();
                        }}
                      >
                        Change
                      </Button>
                    </GridItem>
                  </GridContainer>
                )}
                {insuranceResponse.status === 404 && (
                  <GridContainer
                    sx={{ ...styles.gridCAlign, ...styles.backgroundStyle }}
                  >
                    <GridItem lg={10} md={10} sm={10} xs={10}>
                      <Typography sx={styles.searchPlanText}>
                        {" "}
                        {`We couldn't find an exact match for ${
                          patientName.split(" ")[0]
                        }'s Insurance Plan. Please try searching another plan.`}
                      </Typography>
                    </GridItem>
                    <GridItem
                      lg={2}
                      md={2}
                      sm={2}
                      xs={2}
                      sx={{ ...styles.gridAlign, ...styles.nextLinkStyle }}
                    >
                      <Button
                        onClick={() => {
                          handleChange();
                        }}
                        sx={styles.searchPlanBtn}
                      >
                        Search Plans
                      </Button>
                    </GridItem>
                  </GridContainer>
                )}
              </>
            )}
          </CardContent>
        </Card>
      </GridContainer>
    </>
  );
}
export default PreliminaryCoverageGuideUI;
