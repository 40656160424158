import React, { useEffect } from "react";
import {
  useTheme,
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  ListItem,
  List,
  SwipeableDrawer,
  Box,
  Popover,
  Link,
  Stack,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EyleaPrescriptionPdf from "../assets/eylea_prescriptioninfo.pdf";
import EyleaHDPrescriptionPdf from "../assets/eyleahd_prescriptioninfo.pdf";
import MenuIcon from "@mui/icons-material/Menu";
import eyleaLogo from "../assets/images/logo.png";
import eyleaHDLogo from "../assets/images/logo_hd.png";
import NavLink from "../components/customcomponents/NavLink";
import LogOutDialog from "../components/LogOutDialog";
import payersImage from "../assets/images/payers.svg";
import logoutImage from "../assets/images/logout.svg";
import patientImage from "../assets/images/patient.svg";
import { getDashboardStyle } from "../components/customstyles/DashboardStyle";
import Button from "../components/customcomponents/CustomButton";
import { AuthContext } from "../context/AuthContext";
import { useLocation } from "react-router-dom";
const useStyles = () => {
  const theme = useTheme();
  return getDashboardStyle(theme);
};

const ELEVATION_THRESHOLD = 2;
const PUBLIC_ROUTES = ["/login", "/logout"];

const LogoutButton = ({ setShowLogoutDialog, variant = "small" }) => {
  const styles = useStyles();
  const shouldTextWrap = useMediaQuery("(max-width: 1200px)");
  return (
    <Box
      onClick={() => {
        setShowLogoutDialog(true);
      }}
      sx={{
        ...styles.navbarLogoutStyle,
        padding: shouldTextWrap ? "0px" : "20px 10px",
      }}
    >
      <img
        src={logoutImage}
        style={{
          marginRight: "6px",
        }}
        alt="logout"
      />
      End{variant === "big" && shouldTextWrap ? <br /> : " "}Session
    </Box>
  );
};

function Navbar() {
  const styles = useStyles();
  const isSmallScreen = useMediaQuery("(max-width:810px)");
  const shouldShrink = useMediaQuery("(max-width:1040px)");
  const location = useLocation();
  const { isAuthenticated, logout } = React.useContext(AuthContext);
  const showNavLinks = React.useMemo(
    () => isAuthenticated && !PUBLIC_ROUTES.includes(location.pathname),
    [location, isAuthenticated]
  );
  const [showLogoutDialog, setShowLogoutDialog] = React.useState(false);
  const [state, setState] = React.useState({
    left: false,
  });
  const [scroll, setScroll] = React.useState(window.scrollY);
  const homeLink = "/payers";

  const toggleDrawer = (side, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ state, [side]: open });
  };

  const Nav = [
    {
      to: "/patients",
      image: patientImage,
      title: "Patients",
      style: styles.navBarIconStyle,
    },
    {
      to: "/payers",
      image: payersImage,
      title: "Payers",
      style: styles.navBarIconStyle,
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const sideList = (side) => (
    <Box
      sx={styles.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
    >
      <List>
        {Nav.map((link, i) => (
          <div key={i}>
            <ListItem>
              <Box sx={styles.dispBlk}>
                <NavLink to={link.to}>
                  <img src={link.image} style={link.style} alt={link.title} />
                  {link.title}
                </NavLink>
              </Box>
            </ListItem>
          </div>
        ))}
        <ListItem>
          <Box
            sx={styles.dispBlk}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div style={{ marginLeft: "20px" }}>
              <SafetyInformation />
            </div>
            <div style={{ marginLeft: "10px" }}>
              <PrescribingDropdown
                defaultId={"another-popover"}
                variant={"small"}
                isSmallScreen={isSmallScreen}
              />
            </div>
            <div style={{ marginRight: "65px" }}>
              <LogoutButton setShowLogoutDialog={setShowLogoutDialog} />
            </div>
          </Box>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        position="fixed"
        elevation={scroll > ELEVATION_THRESHOLD ? 2 : 0}
        sx={styles.appBar}
      >
        <Toolbar
          style={{
            padding: 0,
            width: "100%",
            maxWidth: "1440px",
            margin: "0 auto",
          }}
        >
          {/*big screen*/}
          <Typography
            sx={{ display: isSmallScreen ? "none" : "block" }}
            variant="h6"
            color="inherit"
          >
            <NavLink
              sx={{
                padding: shouldShrink ? "8px 5px" : "8px 20px",
                pointerEvents: showNavLinks ? "initial" : "none",
              }}
              to={homeLink}
              activeStyle={{
                borderBottom: "none",
              }}
            >
              <img
                src={eyleaHDLogo}
                alt="eylea HD logo"
                height={shouldShrink ? "30px" : "44px"}
                style={{ margin: "auto 0 auto 10px" }}
              />
              <img
                src={eyleaLogo}
                alt="eylea logo"
                height={shouldShrink ? "34px" : "50px"}
                style={{ margin: "auto 10px" }}
              />
            </NavLink>
          </Typography>
          {showNavLinks && (
            <IconButton
              sx={{
                ...styles.menuButton,
                display: isSmallScreen ? "block" : "none",
              }}
              color="inherit"
              aria-label="Menu"
              onClick={toggleDrawer("left", true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            variant="h6"
            color="inherit"
            sx={{
              display: isSmallScreen ? "block" : "none",
              marginLeft: "10px",
            }}
          >
            <NavLink
              to={homeLink}
              sx={{
                padding: "8px 0px",
                pointerEvents: showNavLinks ? "initial" : "none",
              }}
              activeStyle={{
                borderBottom: "none",
              }}
            >
              <img src={eyleaHDLogo} height="37px" alt="eylea HD logo" />
              <img src={eyleaLogo} height="40px" alt="eylea logo" />
            </NavLink>
          </Typography>
          {/*big screen*/}
          {showNavLinks && (
            <Box
              sx={{
                width: "100%",
                textDecoration: "none",
                display: isSmallScreen ? "none" : "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ minWidth: "184px" }}>
                {Nav.map((link, i) => (
                  <NavLink
                    sx={{ padding: shouldShrink ? "24px 5px" : "24px 17px" }}
                    key={i}
                    to={link.to}
                  >
                    <img src={link.image} style={link.style} alt={link.title} />
                    {link.title}
                  </NavLink>
                ))}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    width: "105px",
                    margin: "auto 0",
                    display: isSmallScreen ? "none" : "block",
                  }}
                >
                  <SafetyInformation />
                </Box>
                <Box
                  sx={{
                    display: isSmallScreen ? "none" : "block",
                  }}
                >
                  <PrescribingDropdown
                    defaultId={"simple-popover"}
                    isSmallScreen={isSmallScreen}
                  />
                </Box>
                <LogoutButton
                  setShowLogoutDialog={setShowLogoutDialog}
                  variant="big"
                />
              </Box>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar style={{ marginTop: "10px" }} />
      <SwipeableDrawer
        sx={{ width: "100%" }}
        open={state.left}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        {sideList("left")}
      </SwipeableDrawer>

      {showLogoutDialog && (
        <LogOutDialog
          logout={logout}
          setShowLogoutDialog={setShowLogoutDialog}
        />
      )}
    </>
  );
}
function SafetyInformation() {
  return (
    <Link
      underline="none"
      target="_blank"
      href={"https://www.eylea4ueportal.com/ImportantSafetyInformation.aspx"}
      sx={{
        color: "rgb(0, 68, 107)",
      }}
    >
      <div>Important Safety Information and Indications</div>
    </Link>
  );
}

function PrescribingDropdown({ defaultId, variant = "big", isSmallScreen }) {
  const styles = useStyles();
  const shouldTextWrap = useMediaQuery("(max-width: 1200px)");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? defaultId : undefined;
  return (
    <>
      <Button
        wrapperSx={{
          margin: shouldTextWrap ? "0px" : "10px",
        }}
        sx={{ padding: "10px", color: "#00446b" }}
        aria-describedby={id}
        variant="text"
        onClick={handleClick}
      >
        <Box
          sx={{
            paddingRight: "5px",
          }}
        >
          Prescribing {shouldTextWrap && variant !== "small" && <br />}{" "}
          Information
        </Box>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock={true}
        elevation={4}
        sx={{
          display:
            variant === "small"
              ? isSmallScreen
                ? "block"
                : "none"
              : isSmallScreen
              ? "none"
              : "block",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack direction="column">
          <Link
            underline="none"
            target="_blank"
            href={EyleaHDPrescriptionPdf}
            onClick={() => {
              handleClose();
            }}
            sx={styles.prescriptionLink}
          >
            EYLEA HD Prescribing Information
          </Link>
          <Link
            underline="none"
            target="_blank"
            href={EyleaPrescriptionPdf}
            onClick={() => {
              handleClose();
            }}
            sx={styles.prescriptionLink}
          >
            EYLEA Prescribing Information
          </Link>
        </Stack>
      </Popover>
    </>
  );
}

export default Navbar;
